<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <nav class="navbar">
            <div class="header-search d-flex align-items-center">
              <router-link class="brand-logo mr-3" to="index">
                <img src="../../assets/images/logo.png" alt="" width="30" />
              </router-link>
              <!-- <form action="#" @submit.prevent="">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"
                      ><i class="fa fa-search"></i
                    ></span>
                  </div>
                </div>
              </form> -->
              <div v-if="userData.primaryCompany">
                <b-form-select :value="selectedCompany" @change="changeSelectedCompany" :options="companyOptions"></b-form-select>
              </div>
              
            </div>

            <div class="dashboard_log">
              <div class="d-flex align-items-center">
                <div
                  class="profile_log dropdown"
                  :class="show && 'show'"
                  @click.capture="show = !show"
                >
                  <div class="user" data-toggle="dropdown">
                    <span class="thumb"><i class="mdi mdi-account"></i></span>
                    <span class="name">{{authUser.fName}} {{authUser.lName}}</span>
                    <span class="arrow"><i class="la la-angle-down"></i></span>
                  </div>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    :class="show && 'show'"
                  >
                    <router-link to="accounts" class="dropdown-item">
                      <i class="mdi mdi-account"></i> Account
                    </router-link>
                    <!-- <router-link to="history" class="dropdown-item">
                      <i class="la la-book"></i> History
                    </router-link> -->
                    <!-- <router-link to="settings" class="dropdown-item">
                      <i class="la la-cog"></i> Setting
                    </router-link> -->
                    <!-- <router-link to="lock" class="dropdown-item">
                      <i class="la la-lock"></i> Lock
                    </router-link> -->
                    <div @click="logoutUsr" class="dropdown-item logout"><i class="la la-sign-out"></i> Logout</div>
                    <!-- <router-link to="signin" class="dropdown-item logout">
                      <i class="la la-sign-out"></i> Logout
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
    computed: {
    ...mapGetters({
      userData: 'userData',
      companies: 'companies',
      companyOptions: 'companyOptions',
      selectedCompany: 'selectedCompany'
    }),
  },
  data() {
    return {
      show: false,
      authUser: {},
    };
  },
  methods: {
    ...mapActions(["fetchUserData", "fetchCompanyData", "changeSelectedCompany"]),
    getAuthenticatedUser(){
        if (!sessionStorage.getItem('authUser'))
            return null;
        this.authUser = JSON.parse(sessionStorage.getItem('authUser'));
    },
    logoutUsr(){
firebase.auth().signOut().then(()=>{
sessionStorage.removeItem('authUser');
this.$router.push("/signin");
});
    }
  },
  mounted(){
    let hostname = location.hostname;
    console.log("hostname ",hostname)
    this.getAuthenticatedUser();
    this.fetchUserData();
    this.fetchCompanyData();
  }
};
</script>
