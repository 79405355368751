<template>
  <Layout :active="1" :title="false">
    <div class="content-body">
      <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
            <div>
                <h4 class="card-title"> {{company ? 'Edit': 'New'}} Company Information</h4>
                <p>Enter your company information as it appears on your official documents.</p>
            </div>
          
          
        </div>
    
        <div class="card-body">
          <ValidationObserver v-slot="{ passes, invalid }">
            <form
              @submit.prevent="passes(formSubmit)"
              method="post"
              name="myform"
              class="personal_validate"
            >
              <div class="form-row">
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Company Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Something Inc"
                      v-model="companyName"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label class="mr-sm-2">Company Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="info@somthing.com"
                      v-model="email"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Company Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="1 758 123 4567"
                      v-model="companyNumber"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Company type</label>
                    <select class="form-control" v-model="companyType">
                      <option value="limited liability company (LLC)">limited liability company (LLC)</option>
                      <option value="Corporation">Corporation</option>
                      <option value="Sole proprietorship">Sole proprietorship</option>
                      <option value="Other">Other</option>
                      </select>
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Company Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Corinth, Gros Islet"
                      v-model="permanentaddress"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Estimated number of transactions per month</label>
                    <select class="form-control" v-model="transactAmount">
                      <option value="100">0 - 100</option>
                      <option value="500">101 - 500</option>
                      <option value="5000">501 - 5,000</option>
                      <option value="20000">5,000 - 20,000</option>
                      <option value="50000">50,000 +</option>
                      </select>
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <!-- <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Postal Code</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="25481"
                      v-model="postal"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div> -->
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Country</label>
                    <select class="form-control" v-model="country">
                      <option value="Saint Lucia">Saint Lucia</option>
                    </select>
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="!submitting" class="form-group col-12">
                  <button type="submit" :disabled="invalid" class="btn btn-success pl-5 pr-5">{{company ? 'Update':'Submit'}}</button>
                </div>
                <div v-else class="form-group col-12">
                    <label class="mr-sm-2">Please wait.</label>
                </div>
                <p>{{txt}}</p>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/dashboard/Layout.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import moment from "moment";
import { companiesCollection, usersCollection } from "@/data/firebaseDatabase";
import firebase from "firebase/compat/app";
import { mapActions } from "vuex";

export default {
    props: {
        company: Object
    },
  components: { 
    Layout,
    ValidationObserver, ValidationProvider,
    // Datepicker,
     },
     data() {
    return {
        authUser: JSON.parse(sessionStorage.getItem("authUser")),
        txt: "Please allow us 24 hours to verify your company information.",
        submitting: false,
      filevalue: "",
      companyName: "",
      email: "",
      companyNumber: "",
      country: "Saint Lucia",
      transactAmount: "500",
    //   postal: "",
      permanentaddress: "",
      companyType: "limited liability company (LLC)",
    //   value1: "10-10-2020",
    //   value2: "",
    };
  },
  methods: {
      ...mapActions([ "fetchUserData"]),
    fileChange(e) {
      this.filevalue = e.target.value;
    },
    async formSubmit() {
      console.log("Submitting to server!");
      this.submitting = true;
      if(this.company){
        await companiesCollection.doc(this.company.id).set({
          lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
          companyName: this.companyName.trim(),
          companyEmail: this.email.trim(),
          companyNumber: this.companyNumber.trim(),
          country: this.country,
          transactAmount: this.transactAmount,
          permanentaddress: this.permanentaddress,
          companyType: this.companyType,
          inReview: true,
          lastReview: "",
      }, {merge:true}).then(async ()=>{
          
          this.$router.push("/companies");
      }).catch((err)=>{
          this.txt = err;
          this.submitting = false;
      });
      }else{
        await companiesCollection.add({
          created: firebase.firestore.FieldValue.serverTimestamp(),
          lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
          balance: 0,
          testBalance: 0,
          lastTransactionTotal: 0,
          testLastTransactionTotal: 0,
          totalTrans: 0,
          testLotalTrans: 0,
          companyName: this.companyName.trim(),
          companyEmail: this.email.trim(),
          companyNumber: this.companyNumber.trim(),
          country: this.country,
          transactAmount: this.transactAmount,// how many transactions per month you estimate ( string)
          permanentaddress: this.permanentaddress,
          companyType: this.companyType,
          payoutPending: false,
          inReview: true,
          lastReview: "",
          companyOwner: this.authUser.uid,
          standardUsers: [
              this.authUser.uid
          ]
      }).then(async (docReference)=>{
          await usersCollection.doc(this.authUser.uid).update({
              primaryCompany: docReference.id
          });
          this.fetchUserData();
          this.$router.push("/index");
      }).catch((err)=>{
          this.txt = err;
          this.submitting = false;
      });
      }
      
    },
    customFormatter(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  mounted(){
    if(this.company){
      this.companyName = this.company.data().companyName;
    this.email = this.company.data().companyEmail;
    this.companyNumber = this.company.data().companyNumber;
    this.country = this.company.data().country;
    this.transactAmount = this.company.data().transactAmount;
    this.permanentaddress = this.company.data().permanentaddress;
    this.companyType = this.company.data().companyType;
    }
    
  }
};
</script>
