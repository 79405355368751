<template>
  <Layout :active="3" :title="false">
    <div class="content-body">
      <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Personal Information</h4>
        </div>
        <div class="card-body">
          <ValidationObserver v-slot="{ passes }">
            <form
              @submit.prevent="passes(formSubmit)"
              method="post"
              name="myform"
              class="personal_validate"
            >
              <div class="form-row">
                
                      <div class="form-group col-xl-6">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                          name="method"
                          class="form-group"
                          tag="div"
                        >
                        <label>First name</label>
                          <input
                            type="text"
                            class="form-control error"
                            placeholder="First name"
                            name="fName"
                            v-model="fName"
                            aria-invalid="true"
                          />
                          <label class="error" for="fName">{{
                            errors[0]
                          }}</label>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-xl-6">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                          name="method"
                          class="form-group"
                          tag="div"
                        >
                        <label>Last name</label>
                          <input
                            type="text"
                            class="form-control error"
                            placeholder="Last name"
                            name="lName"
                            v-model="lName"
                            aria-invalid="true"
                          />
                          <label class="error" for="lName">{{
                            errors[0]
                          }}</label>
                        </ValidationProvider>
                      </div>
                  <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Phone</label>
                    <input
                      type="tel"
                      name="phone"
                      class="form-control"
                      placeholder="1 758 123 1231"
                      v-model="phone"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Address</label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      placeholder="Corinth Gros islet"
                      v-model="address"
                    />
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="mr-sm-2">Country</label>
                    <b-form-select
                    name="country"
                      v-model="country"
                      :options="options"
                      class="form-control"
                    ></b-form-select><br>
                    <label class="error">{{ errors[0] }}</label>
                  </ValidationProvider>
                </div>
                <div class="form-group col-xl-6"></div>

                <div class="form-group col-12">
                  <button type="submit" v-if="!submitting" class="btn btn-success pl-5 pr-5">Save</button>
                  <b-spinner v-else label="Spinning"></b-spinner>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/dashboard/Layout.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import firebase from "firebase/compat/app";
import { usersCollection } from "../data/firebaseDatabase";
// import { salesCollection } from "@/data/firebaseDatabase";
import { mapGetters } from "vuex";

export default {
  components: { 
    Layout,
    ValidationObserver, ValidationProvider,
     },
     computed: {
       ...mapGetters({
         userData: 'userData',
       })
     },
     data() {
    return {
      phone: "",
      submitting: false,
      address: "",
      filevalue: "",
      fName: "",
      lName: "",
      country: "Saint Lucia",
      options: [{ value: "Saint Lucia", text: "Saint Lucia" }],
      
    };
  },
  methods: {
    getUserData(){
      this.fName= this.userData.fName;
      this.lName= this.userData.lName;
      this.country = this.userData.country;
      this.address = this.userData.address;
      this.phone = this.userData.phone;
    },
    fileChange(e) {
      this.filevalue = e.target.value;
    },
    async formSubmit() {
      if(this.fName == null || this.lName == undefined || this.lName == null
       || this.lName == undefined || this.address == undefined || this.address == null ||
       this.country == null || this.country == undefined ||
       this.phone == null || this.phone == undefined ){
         return;
      }
      this.submitting = true;
      if(this.userData.lName != this.lName.trim() || this.fName.trim() != this.userData.fName){
        await firebase.auth().currentUser.updateProfile({
              displayName: `${this.fName.trim()} ${this.lName.trim()}`,
            })
      }
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      await usersCollection.doc(authUser.uid).update({
        fName: this.fName.trim(),
        lName: this.lName.trim(),
        country: this.country,
        address: this.address.trim(),
        phone: this.phone.trim(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(()=>{
        this.$router.push("/accounts");
      });
      console.log("Submitting to server!");
    },
  },
  mounted(){
    this.getUserData();
  }
};
</script>
