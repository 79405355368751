<template>
  <layout>
    <div class="content-body">
      <div class="verification section-padding mb-80">
        <div class="container h-100">
          <div
            class="row justify-content-center h-100 align-items-center  my-5"
          >
            <div class="col-xl-5 col-md-6">
              <div class="auth-form card">
                <div class="card-header">
                  <h4 class="card-title">Link a bank account</h4>
                </div>
                <div class="card-body">
                  <form @submit.prevent="formSubmit" class="identity-upload">
                    <div class="form-row">
                      <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Country</label>
                    <select class="form-control" v-model="country">
                      <option value="Saint Lucia">Saint Lucia</option>
                    </select>
                </div>
                      <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Bank name</label>
                    <select class="form-control" v-model="bankName">
                      <option v-for="bank in bankList" :key="bank" :value="bank">{{bank}}</option>
                    </select>
                </div>
                      <div class="form-group col-xl-12">
                        <label class="mr-sm-2">Routing number </label>
                        <input
                        v-model="accountRouting"
                          type="text"
                          class="form-control"
                          placeholder="25487"
                        />
                      </div>
                      <div class="form-group col-xl-12">
                        <label class="mr-sm-2">Account number </label>
                        <input
                        v-model="accountNumber"
                          type="text"
                          class="form-control"
                          placeholder="36475 2234 1231 123"
                        />
                      </div>
                      <div class="form-group col-xl-12">
                        <label class="mr-sm-2">Name on account</label>
                        <input
                        v-model="accountName"
                          type="text"
                          class="form-control"
                          placeholder="John Smith / something inc"
                        />
                      </div>
                      <div class="form-group col-xl-12">
                        <img
                          src="../assets/images/routing.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div class="text-center col-12">
                        <router-link
                        v-if="!isLoading"
                          :to="myBankId ? 'accounts': 'verify-step-5'"
                          class="btn btn-primary mx-2"
                          >Back</router-link
                        >
                        <div v-if="isLoading">Please wait...</div>
                        <button v-else type="submit" class="btn btn-success mx-2">
                          {{ myBankId ? 'EDIT':'Save'}}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "../components/dashboard/Layout.vue";
import firebase from "firebase/compat/app"
import { bankAccountCol } from "../data/firebaseDatabase";

export default {
  components: { Layout },
  data(){
    return {
      myBankId: "",
      accountName: "",
      accountRouting: "",
      accountNumber: "",
      isLoading: false,
      country: "Saint Lucia",
      bankName: "Bank of Saint Lucia Limited",
      bankList: [
        "Bank of Saint Lucia Limited",
        "1st National Bank St. Lucia Ltd",
        "CIBC First Caribbean Bank",
        "Scotiabank",
      ]
    }
  },
  methods: {
    async formSubmit() {
      this.isLoading = true;
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      if(this.myBankId){
        await bankAccountCol.doc(authUser.uid).set({
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        accountRouting: this.accountRouting,
        country: this.country,
        bankName: this.bankName,
        inReview: true,
        reviewTxt: null
      }, {merge: true}).then(()=>{
        this.$router.push("/accounts");
      })
      }else{
        await bankAccountCol.doc(authUser.uid).set({
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        accountRouting: this.accountRouting,
        country: this.country,
        bankName: this.bankName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        inReview: true,
        reviewTxt: null
      }).then(()=>{
        this.$router.push("/verify-step-6");
      })
      }
      
      
    },
    async getBankInfo(){
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      await bankAccountCol.doc(authUser.uid)
      .get().then((bank)=>{
        if(bank.exists){
          this.myBankId = bank.id,
          this.accountName = bank.data().accountName ?? "";
        this.accountRouting = bank.data().accountRouting ?? "";
        this.accountNumber = bank.data().accountNumber ?? "";
        this.country = bank.data().country ?? "";
        this.bankName = bank.data().bankName ?? "";
        }
      })
    },
    getData(){
      
      if(this.bank){
        this.accountName = this.bank.data().accountName ?? "";
        this.accountRouting = this.bank.data().accountRouting ?? "";
        this.accountNumber = this.bank.data().accountNumber ?? "";
        this.country = this.bank.data().country ?? "";
        this.bankName = this.bank.data().bankName ?? "";
      }
    }
  },
  mounted(){
    this.getBankInfo();
  }
};
</script>
