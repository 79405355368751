<template>
  <layout :active="6" :title="true">
    <div class="content-body">
      <div class="container">
          <div class="col-xl-12 col-lg-12 col-xxl-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row" style="justify-content: space-between;width: 100%;">
                      <div class="col-md-10">
                        <h4 class="card-title">Test Transactions</h4>
                      </div>
                      <div class="col-md-2">
                        <div class="row">
                          <!-- <div class="col=md-4" style="padding-right:10px;">
                            <b-form-select v-model="selectedCountry" :options="countryList"></b-form-select>
                          </div> -->
                          <div v-if="userData.primaryCompany" class="col=md-4">
                            <b-form-select v-model="selectedRange" :options="rangeList" @change="getAllTrans"></b-form-select>
                          </div>
                          <div v-else class="col=md-4">Create a company first.</div>
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  <div class="card-body">
                    <div class="transaction-widget">
                      <ul v-for="singleTrans in allTransactions" :key="singleTrans.id" class="list-unstyled">
                        <li class="media">
                          <span v-if="singleTrans.status == 'Approved'" class="buy-thumb"
                            ><i class="la la-arrow-up"></i
                          ></span>
                          <span v-else class="sold-thumb"
                            ><i class="la la-exclamation-triangle"></i
                          ></span>
                          <div class="media-body">
                            <p>
                              <small>{{customFormatter(singleTrans.created.toDate())}} </small>
                             
                            </p>
                            <p v-if="singleTrans.response" class="wallet-address text-dark">
                              Trans Id: {{singleTrans.response.TranId[0]}}
                            </p>
                            <p v-if="singleTrans.response" class="wallet-address text-dark">
                              Invoice No.: {{singleTrans.response.Invoice[0]}}
                            </p>
                          </div>
                          <div v-if="singleTrans.personName && singleTrans.personPhone && singleTrans.personEmail" class="media-body">
                            <p>
                              <small>{{singleTrans.personName}} </small>
                             
                            </p>
                            <p class="wallet-address text-dark">
                              {{singleTrans.personPhone}}
                            </p>
                            <p class="wallet-address text-dark">
                              {{singleTrans.personEmail}}
                            </p>
                          </div>
                          <!-- personal data at top-->
                          <div class="media-body">
                            <p>
                              <small>**** **** **** {{singleTrans.lastFour}}</small>
                            </p>
                            <p class="wallet-address text-dark">{{singleTrans.cName}}</p>
                          </div>
                          <!-- purpose-->
                          <div class="media-body">
                            <p class="wallet-address text-dark">Payment Description</p>
                            <p>
                              <small>{{ singleTrans.purpose }}</small>
                            </p>
                            
                          </div>
                          <div class="text-right">
                            <h4 v-if="singleTrans.response">${{singleTrans.response.Amount[0]}} {{singleTrans.response.Currency[0]}}</h4>
                            <p>
                              <small>Fee: ${{singleTrans.feeAmount}} XCD ({{singleTrans.feePercent}}%)</small>
                            </p>
                            
                            <small v-if="singleTrans.status">{{singleTrans.status}}</small>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        
      </div>
    </div>
    <!-- <pop v-show="showVideo" :closeFuntion="closeFuntion" /> -->
  </layout>
</template>

<script>
import Layout from "../../components/dashboard/Layout.vue";
// import Pop from "../../components/dashboard/Pop.vue";
// import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import { testSalesCollection } from "@/data/firebaseDatabase";
import moment from "moment";

export default {
  name: "Index",
  components: {
    Layout,
    // Pop,
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
       companies: 'companies',
    })
  },
  data() {
    return {
      allTransactions: [],
      showVideo: false,
      selectedCountry: 'Saint Lucia',
        countryList: [
          { value: 'All', text: 'All Countries' },
          { value: 'Saint Lucia', text: 'Saint Lucia' },
        ],
        selectedRange: 1,
        rangeList: [
          { value: 1, text: 'Today' },
          { value: 7, text: 'Last 7 days' },
          { value: 30, text: 'Last 30 days' },
        ]
    };
  },
  methods: {
    customFormatter(date) {
      return moment(date).format(" DD MMMM YYYY h:mm a");
    },
    async getAllTrans(){
      this.allTransactions = [];
      let today = new Date();
// let start = today.setDate(0);
// var month = 0; // January
// var month = today.getMonth();
let lastEnd = new Date();
lastEnd.setDate(lastEnd.getDate()- this.selectedRange);
console.log("lastEnd "+lastEnd);
console.log("today2 "+today);
if(this.userData.primaryCompany){
  await testSalesCollection.where("companyId", "==", this.userData.primaryCompany)
      .where("created", '>', lastEnd).where("created", '<', today)
      .orderBy("created", "desc").get().then((salesData)=>{
        if(salesData.size > 0){
          salesData.docs.forEach((singleSale)=>{
            let company = this.companies.find(mData => mData.companyId == singleSale.companyId);
            // console.log("company "+company);
            var data = {
              cName: company.data().companyName,
              id: singleSale.id,
              ...singleSale.data()
            }
            this.allTransactions.push(data);
          })
        }
      });
}
    
    },
    closeFuntion() {
      this.showVideo = false;
    },
  },
  mounted(){
    this.getAllTrans();
  }
};
</script>
