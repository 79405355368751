//Page: SignUp
<template>
  <div class="authincation section-padding" style="background-color: #00aeef">
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-header justify-content-center">
              <div class="mini-logo text-center">
                <router-link to="/"
                  ><img
                    src="../assets/images/logo-long.png"
                    width="90px"
                    alt=""
                /></router-link>
              </div>
            </div>
            <div class="card-body">
              <ValidationObserver v-slot="{ passes, invalid }">
                <form
                  @submit.prevent="passes(formSubmit)"
                  method="post"
                  name="myform"
                  class="signup_validate"
                >
                  <div class="form-group">
                    <h4 class="card-title text-center">Sign up your account</h4>
                    <label>Name</label>
                    <div class="row">
                      <div class="col-xl-6 col-md-6">
                        <ValidationProvider
                          rules="required|text"
                          v-slot="{ errors }"
                          name="method"
                          class="form-group"
                          tag="div"
                        >
                          <input
                            type="text"
                            class="form-control error"
                            placeholder="First name"
                            name="fName"
                            v-model="fName"
                            aria-invalid="true"
                          />
                          <label class="error" for="fName">{{
                            errors[0]
                          }}</label>
                        </ValidationProvider>
                      </div>
                      <div class="col-xl-6 col-md-6">
                        <ValidationProvider
                          rules="required|text"
                          v-slot="{ errors }"
                          name="method"
                          class="form-group"
                          tag="div"
                        >
                          <input
                            type="text"
                            class="form-control error"
                            placeholder="Last name"
                            name="lName"
                            v-model="lName"
                            aria-invalid="true"
                          />
                          <label class="error" for="lName">{{
                            errors[0]
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="mr-sm-2">Country</label>
                    <b-form-select
                      v-model="country"
                      :options="options"
                      class="form-control"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                      name="method"
                      class="form-group"
                      tag="div"
                    >
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control error"
                        placeholder="hello@example.com"
                        name="email"
                        v-model="email"
                        aria-invalid="true"
                      />
                      <label class="error" for="email">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      rules="passwordFieldName|verify_password"
                      v-slot="{ errors }"
                      name="password"
                      class="form-group"
                      tag="div"
                    >
                      <label>Password</label>
                      <input
                        type="password"
                        class="form-control error"
                        placeholder="Password"
                        name="password"
                        v-model="password"
                        aria-invalid="true"
                      />
                      <label class="error" for="password">{{
                        errors[0]
                      }}</label>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      vid="confirm"
                      rules="required"
                      v-slot="{ errors }"
                      name="Password confirmation"
                      class="form-group"
                      tag="div"
                    >
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        class="form-control error"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        v-model="confirmpassword"
                        aria-invalid="true"
                      />
                      <label class="error" for="confirmpassword">{{
                        errors[0]
                      }}</label>
                    </ValidationProvider>
                  </div>
                  <div class="text-center mt-4">
                    <label style="color: red">{{ loginError }}</label>
                    <button
                      type="submit"
                      v-if="!submitting"
                      class="btn btn-success btn-block"
                      :disabled="invalid"
                    >
                      Sign up
                    </button>
                    <b-spinner v-else label="Spinning"></b-spinner>
                  </div>
                </form>
              </ValidationObserver>
              <div class="new-account mt-3">
                <p>
                  Already have an account?
                  <router-link class="text-primary" to="/signin"
                    >Sign in</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import firebase from "firebase/compat/app";
import { usersCollection } from "../data/firebaseDatabase";
export default {
  name: "Signup",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: "",
      password: "",
      confirmpassword: "",
      submitting: false,
      loginError: "",
      fName: "",
      lName: "",
      country: "Saint Lucia",
      options: [{ value: "Saint Lucia", text: "Saint Lucia" }],
    };
  },
  methods: {
    async formSubmit() {
      console.log("wait...");
      if (this.password != this.confirmpassword) {
        this.loginError = "Passwords dont match!";
      } else {
        this.submitting = true;
        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then(async (user) => {
            await usersCollection
              .doc(user.user.uid)
              .set({
                email: user.user.email,
                // isVerified: user.user.emailVerified,
                // companies: [],
                primaryCompany: null,
                phone: null,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                country: this.country,
                fName: this.fName.trim(),
                lName: this.lName.trim(),
                role: 3,
                address: null,
              })
              .then(async () => {
                await user.user.updateProfile({
                  displayName: `${this.fName} ${this.lName}`,
                });
                user.user.sendEmailVerification();
                sessionStorage.setItem("authUser", JSON.stringify(user.user));
                this.$router.push("/");
              });
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode == "auth/weak-password") {
              alert("The password is too weak.");
            } else {
              this.loginError = errorMessage;
              alert(errorMessage);
            }
            console.log(error);
          });
        this.submitting = false;
      }
    },
  },
};
</script>
