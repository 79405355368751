<template>
  <div class="sidebar">
    <router-link class="brand-logo" to="/index">
      <img src="../../assets/images/logo.png" alt="" />
      <span>Getit Payments </span></router-link
    >
    <div class="menu">
      <ul>
        <li :class="active === 1 && 'active'">
          <router-link to="/index" :class="active === 1 && 'active'">
            <span><i class="mdi mdi-view-dashboard"></i></span>
            <span class="nav-text">Home</span>
          </router-link>
        </li>
        <li :class="active === 2 && 'active'">
          <router-link to="mytransactions" :class="active === 2 && 'active'">
            <span><i class="mdi mdi-repeat"></i></span>
            <span class="nav-text">Transactions</span>
          </router-link>
        </li>
        <li :class="active === 3 && 'active'">
          <router-link to="accounts" :class="active === 3 && 'active'">
            <span><i class="mdi mdi-account"></i></span>
            <span class="nav-text">Account</span>
          </router-link>
        </li>
        <!-- <li :class="active === 4 && 'active'">
          <router-link to="settings" :class="active === 4 && 'active'">
            <span><i class="mdi mdi-settings"></i></span>
            <span class="nav-text">Setting</span>
          </router-link>
        </li> -->
        <li :class="active === 4 && 'active'">
          <router-link to="payouts" :class="active === 4 && 'active'">
            <span><i class="mdi mdi-chart-pie"></i></span>
            <span class="nav-text">Payouts</span>
          </router-link>
        </li>
        <li :class="active === 5 && 'active'">
          <router-link to="companies" :class="active === 5 && 'active'">
            <span><i class="mdi mdi-bank"></i></span>
            <span class="nav-text">Companies</span>
          </router-link>
        </li>
        <li :class="active === 6 && 'active'">
          <router-link to="TestTransactions" :class="active === 6 && 'active'">
            <span><i class="mdi mdi-alien"></i></span>
            <span class="nav-text">Test Transactions</span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="sidebar-footer">
      <div class="social">
        <a to="#"><i class="fa fa-youtube-play"></i></a>
        <a to="#"><i class="fa fa-instagram"></i></a>
        <a to="#"><i class="fa fa-twitter"></i></a>
        <a to="#"><i class="fa fa-facebook"></i></a>
      </div>
      <div class="copy_right">© {{ new Date().getFullYear() }} GETIT</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    active: Number,
  },
};
</script>
