<template>
  <layout :active="2" :title="true">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="transactions"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="842px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <div class="content-body">
          <div class="container">
            <div style="col-xl-12 col-lg-12 col-xxl-12">
              <div class="card">
                <div class="card-header">
                  <div class="col-md-12">
                    <div
                      class="row"
                      style="justify-content: space-between; width: 100%"
                    >
                      <div class="col-md-10">
                        <h4 class="card-title">{{ pdfTitle }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="transaction-widget">
                    <ul
                      v-for="(singleTrans, index) in allTransactions"
                      :key="index"
                      class="list-unstyled"
                    >
                      <li class="media">
                        <span
                          v-if="singleTrans.status == 'Approved'"
                          class="buy-thumb"
                          ><i class="la la-arrow-up"></i
                        ></span>
                        <span v-else class="sold-thumb"
                          ><i class="la la-exclamation-triangle"></i
                        ></span>
                        <div class="media-body">
                          <p>
                            <small
                              >{{
                                customFormatter(singleTrans.created.toDate())
                              }}
                            </small>
                          </p>
                          <p
                            v-if="
                              singleTrans.response &&
                              singleTrans.response.TranId
                            "
                            class="wallet-address text-dark"
                          >
                            Trans Id: {{ singleTrans.response.TranId[0] }}
                          </p>
                          <p
                            v-if="
                              singleTrans.response &&
                              singleTrans.response.Invoice
                            "
                            class="wallet-address text-dark"
                          >
                            Invoice No.: {{ singleTrans.response.Invoice[0] }}
                          </p>
                        </div>
                        <div
                          v-if="
                            singleTrans.personName &&
                            singleTrans.personPhone &&
                            singleTrans.personEmail
                          "
                          class="media-body"
                        >
                          <p>
                            <small>{{ singleTrans.personName }} </small>
                          </p>
                          <p class="wallet-address text-dark">
                            {{ singleTrans.personPhone }}
                          </p>
                          <p class="wallet-address text-dark">
                            {{ singleTrans.personEmail }}
                          </p>
                        </div>
                        <!-- personal data at top-->
                        <div class="media-body">
                          <p>
                            <small
                              >**** **** **** {{ singleTrans.lastFour }}</small
                            >
                          </p>
                          <p class="wallet-address text-dark">
                            {{ singleTrans.cName }}
                          </p>
                        </div>
                        <!-- purpose-->
                        <div class="media-body">
                          <p class="wallet-address text-dark">
                            Payment Description
                          </p>
                          <p>
                            <small>{{
                              singleTrans.purpose ? singleTrans.purpose : "N/A"
                            }}</small>
                          </p>
                        </div>
                        <div
                          v-if="
                            singleTrans.response.Amount &&
                            singleTrans.response.Currency
                          "
                          class="text-right"
                        >
                          <h4 v-if="singleTrans.response">
                            ${{ singleTrans.response.Amount[0] }}
                            {{ singleTrans.response.Currency[0] }}
                          </h4>
                          <p>
                            <small
                              >Fee: ${{ singleTrans.feeAmount.toFixed(3) }} XCD
                              ({{ singleTrans.feePercent }}%)</small
                            >
                          </p>

                          <small v-if="singleTrans.status">{{
                            singleTrans.status
                          }}</small>
                        </div>
                        <div v-else class="text-right">
                          <h4></h4>
                          <p>
                            <small
                              >Fee: ${{ singleTrans.feeAmount.toFixed(3) }} XCD
                              ({{ singleTrans.feePercent }}%)</small
                            >
                          </p>
                          <small v-if="singleTrans.status">{{
                            singleTrans.status
                          }}</small>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <div class="content-body">
      <div class="container">
        <div style="col-xl-12 col-lg-12 col-xxl-12">
          <div class="card">
            <div class="card-header">
              <div class="col-md-12">
                <div
                  class="row"
                  style="justify-content: space-between; width: 100%"
                >
                  <div class="col-md-2">
                    <h4 class="card-title">Transactions</h4>
                  </div>

                  <div class="col-md-10">
                    <div
                      class="row"
                      style="justify-content: space-between; width: 100%"
                    >
                      <!-- <div class="col=md-4" style="padding-right:10px;">
                            <b-form-select v-model="selectedCountry" :options="countryList"></b-form-select>
                          </div> -->

                      <!-- <b-form-select
                          v-model="selectedRange"
                          :options="rangeList"
                          @change="getAllTrans"
                        ></b-form-select> -->

                      <form
                        v-if="userData.primaryCompany"
                        @submit.prevent="formSubmit"
                        style="justify-content: space-between; width: 100%"
                        class="row"
                      >
                        <datepicker
                          placeholder="From"
                          class="editPage-piker col=md-2"
                          v-model="startDate"
                          :format="customDateFormatter"
                        ></datepicker>
                        <div class="col=md-2">To:</div>
                        <datepicker
                          placeholder="To"
                          class="editPage-piker col=md-2"
                          v-model="endDate"
                          :format="customDateFormatter"
                        ></datepicker>
                        <div class="col=md-2">
                          <div class="row">
                            <button
                              :disabled="startDate == null || endDate == null"
                              type="submit"
                              name="submit"
                              class="btn-primary btn-sm"
                            >
                              FIND
                            </button>
                          </div>
                        </div>

                        <!-- <button v-if="allTransactions.length > 0"
                          @click="generateReport()"
                          class="btn-success btn-sm col=md-2"
                        >
                          Download
                        </button> -->
                        <div v-if="allTransactions.length > 0" class="col=md-2">
                          <div class="row">
                            <button
                              @click="createCSV()"
                              class="btn-success btn-sm"
                            >
                              <i class="fa fa-file-excel-o">CSV</i>
                            </button>
                          </div>
                          <div class="row">
                            <button
                              @click="generateReport()"
                              class="btn-danger btn-sm"
                            >
                              <i class="fa fa-file-pdf-o">PDF</i>
                            </button>
                          </div>
                        </div>
                      </form>

                      <div v-else class="col=md-4">Create a company first.</div>
                    </div>
                  </div>
                </div>
                <div class="row">Approved: ${{ totalApproved.toFixed(2) }}</div>
              </div>
            </div>
            <div class="card-body">
              <div class="transaction-widget">
                <ul
                  v-for="singleTrans in allTransactions"
                  :key="singleTrans.id"
                  class="list-unstyled"
                >
                  <li class="media">
                    <span
                      v-if="singleTrans.status == 'Approved'"
                      class="buy-thumb"
                      ><i class="la la-arrow-up"></i
                    ></span>
                    <span v-else class="sold-thumb"
                      ><i class="la la-exclamation-triangle"></i
                    ></span>
                    <div class="media-body">
                      <p>
                        <small
                          >{{ customFormatter(singleTrans.created.toDate()) }}
                        </small>
                      </p>
                      <p
                        v-if="
                          singleTrans.response && singleTrans.response.TranId
                        "
                        class="wallet-address text-dark"
                      >
                        Trans Id: {{ singleTrans.response.TranId[0] }}
                      </p>
                      <p
                        v-if="
                          singleTrans.response && singleTrans.response.Invoice
                        "
                        class="wallet-address text-dark"
                      >
                        Invoice No.: {{ singleTrans.response.Invoice[0] }}
                      </p>
                    </div>
                    <div
                      v-if="
                        singleTrans.personName &&
                        singleTrans.personPhone &&
                        singleTrans.personEmail
                      "
                      class="media-body"
                    >
                      <p>
                        <small>{{ singleTrans.personName }} </small>
                      </p>
                      <p class="wallet-address text-dark">
                        {{ singleTrans.personPhone }}
                      </p>
                      <p class="wallet-address text-dark">
                        {{ singleTrans.personEmail }}
                      </p>
                    </div>
                    <!-- personal data at top-->
                    <div class="media-body">
                      <p>
                        <small>**** **** **** {{ singleTrans.lastFour }}</small>
                      </p>
                      <p class="wallet-address text-dark">
                        {{ singleTrans.cName }}
                      </p>
                    </div>
                    <!-- purpose-->
                    <div class="media-body">
                      <p class="wallet-address text-dark">
                        Payment Description
                      </p>
                      <p>
                        <small>{{
                          singleTrans.purpose ? singleTrans.purpose : "N/A"
                        }}</small>
                      </p>
                    </div>
                    <div
                      v-if="
                        singleTrans.response.Amount &&
                        singleTrans.response.Currency
                      "
                      class="text-right"
                    >
                      <h4 v-if="singleTrans.response">
                        ${{ singleTrans.response.Amount[0] }}
                        {{ singleTrans.response.Currency[0] }}
                      </h4>
                      <p>
                        <small
                          >Fee: ${{ singleTrans.feeAmount.toFixed(3) }} XCD ({{
                            singleTrans.feePercent
                          }}%)</small
                        >
                      </p>

                      <small v-if="singleTrans.status">{{
                        singleTrans.status
                      }}</small>
                    </div>
                    <div v-else class="text-right">
                      <h4></h4>
                      <p>
                        <small
                          >Fee: ${{ singleTrans.feeAmount.toFixed(3) }} XCD ({{
                            singleTrans.feePercent
                          }}%)</small
                        >
                      </p>
                      <small v-if="singleTrans.status">{{
                        singleTrans.status
                      }}</small>
                    </div>
                  </li>
                </ul>
                <div style="padding-bottom: 250px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pop v-show="showVideo" :closeFuntion="closeFuntion" /> -->
  </layout>
</template>

<script>
import Layout from "../../components/dashboard/Layout.vue";
// import Pop from "../../components/dashboard/Pop.vue";
// import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import { salesCollection } from "@/data/firebaseDatabase";
import moment from "moment";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Index",
  components: {
    Layout,
    Datepicker,
    VueHtml2pdf,
    // Pop,
  },
  computed: {
    ...mapGetters({
      userData: "userData",
      companies: "companies",
    }),
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      pdfTitle: "",
      totalApproved: 0,
      allTransactions: [],
      showVideo: false,
      selectedCountry: "Saint Lucia",
      countryList: [
        { value: "All", text: "All Countries" },
        { value: "Saint Lucia", text: "Saint Lucia" },
      ],
      selectedRange: 1,
      rangeList: [
        { value: 1, text: "Today" },
        { value: 7, text: "Last 7 days" },
        { value: 30, text: "Last 30 days" },
      ],
    };
  },
  methods: {
    createCSV() {
      let rows = [];
      //row one: total counts:
      rows.push([this.pdfTitle]);
      rows.push([""]);
      rows.push([
        "Status",
        "Total",
        "Fee",
        "Email",
        "Name",
        "purpose",
        "Invoice number",
        "Date",
      ]);

      this.allTransactions.forEach((singleTrans) => {
        rows.push([
          singleTrans.status,
          singleTrans.response.Amount[0],
          singleTrans.feeAmount.toFixed(3),
          singleTrans.personEmail,
          singleTrans.personName,
          singleTrans.purpose,
          singleTrans.response.Invoice[0],
          this.customFormatter(singleTrans.created.toDate()),
        ]);
      });

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach((rowArray) => {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    },
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    onProgress(event) {
      console.log("pdf progress", event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    formSubmit() {
      console.log("Submitting to server!");
      // let startD = new Date(this.startDate);
      let startD = new Date(this.startDate.toDateString());
      console.log(startD);
      let endD = new Date(this.endDate); // Datetime now
      endD.setHours(24, 0, 0, 0);
      console.log("endD: ", endD);
      if (new Date(startD) > new Date(endD)) {
        alert("End date must be older than start date");
        return;
      }
      this.getCustomAllTrans(startD, endD);
      // this.pdfTitle = `${customDateFormatter(startD)} ${}`;
      // this.generateReport();
    },
    customDateFormatter(date) {
      return moment(date).format(" DD MMMM YYYY");
    },
    customFormatter(date) {
      return moment(date).format(" DD MMMM YYYY h:mm a");
    },
    async getCustomAllTrans(start, end) {
      this.allTransactions = [];
      this.totalApproved = 0;
      this.pdfTitle = `${start} - ${end}`;
      if (this.userData.primaryCompany) {
        await salesCollection
          .where("companyId", "==", this.userData.primaryCompany)
          .where("created", ">", start)
          .where("created", "<", end)
          .orderBy("created", "desc")
          .get()
          .then((salesData) => {
            if (salesData.size > 0) {
              salesData.docs.forEach((singleSale) => {
                let company = this.companies.find(
                  (mData) => mData.companyId == singleSale.companyId
                );
                // console.log("company "+company);
                var data = {
                  cName: company.data().companyName,
                  id: singleSale.id,
                  ...singleSale.data(),
                };
                if (data.status == "Approved") {
                  this.totalApproved =
                    this.totalApproved + parseFloat(data.amount);
                }
                this.allTransactions.push(data);
              });
            }
          });
      }
    },
    async getAllTrans() {
      this.allTransactions = [];
      let today = new Date();
      // let start = today.setDate(0);
      // var month = 0; // January
      // var month = today.getMonth();
      let lastEnd = new Date();
      lastEnd.setDate(lastEnd.getDate() - this.selectedRange);
      console.log("lastEnd " + lastEnd);
      console.log("today2 " + today);
      if (this.userData.primaryCompany) {
        await salesCollection
          .where("companyId", "==", this.userData.primaryCompany)
          .where("created", ">", lastEnd)
          .where("created", "<", today)
          .orderBy("created", "desc")
          .get()
          .then((salesData) => {
            if (salesData.size > 0) {
              salesData.docs.forEach((singleSale) => {
                let company = this.companies.find(
                  (mData) => mData.companyId == singleSale.companyId
                );
                // console.log("company "+company);
                var data = {
                  cName: company.data().companyName,
                  id: singleSale.id,
                  ...singleSale.data(),
                };
                if (data.status == "Approved") {
                  this.totalApproved =
                    this.totalApproved + parseFloat(data.amount);
                }
                this.allTransactions.push(data);
              });
            }
          });
      }
    },
    closeFuntion() {
      this.showVideo = false;
    },
  },
  mounted() {
    this.getAllTrans();
  },
};
</script>
