<template>
  <div id="features" class="features section-padding">
    <div class="container">
      <div class="row py-lg-5 justify-content-center">
        <div class="col-xl-7">
          <div class="section_heading">
            <span>Features</span>
            <h3>Only the features you need</h3>
            <p>Here are a few reasons why businesses use GetIt Payments</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="features-content">
            <span><i class="fa fa-shield"></i></span>
            <h4>Secure Transactions</h4>
            <p>
              GetIt Payments uses only the latest in RSA End to End Encryption to ensure every transaction is safe.
            </p>
            <a href="#">Learn more <i class="la la-angle-right"></i></a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="features-content">
            <span><i class="fa fa-cubes"></i></span>
            <h4>Managed Locally</h4>
            <p>
              GetIt Payments is managed by local developers right here in Saint Lucia. Your money is also routed locally.
            </p>
            <a href="#">Learn more <i class="la la-angle-right"></i></a>
          </div>
        </div>
      
      </div>
      <div class="row" style="padding-top:10px;">
        <div class="col-md-6">
          <div class="features-content">
            <span><i class="fa fa-life-ring"></i></span>
            <h4>Low Transaction Fee</h4>
            <p>
              With a <b>6.5% Transaction Fee</b>, Getit Payments has one of the lowest fees in the industry.
            </p>
            <a href="#">Learn more <i class="la la-angle-right"></i></a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="features-content">
            <span><i class="fa fa-life-ring"></i></span>
            <h4>Weekly Payouts</h4>
            <p>
              Your money is transfered to your bank account every friday. No need to wait 30 days.
            </p>
            <a href="#">Learn more <i class="la la-angle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
};
</script>
