<template>
  <Layout :active="5" :title="true">
    <div class="content-body">
      <div class="container">
          <div class="row">
              <div style="text-align:right;" class="col-xl-12 col-lg-12 col-md-12">
                <router-link to="/edit-company" class="btn btn-primary px-4 mr-3"
              >Add New Company</router-link
            >
            </div>
            
          </div>
          <br />
        <div class="row">
          <div v-for="company in companies" :key="company.id" class="col-xl-6 col-lg-6 col-md-12">
            <div class="card profile_card">
              <div class="card-body">
                  <div class="media">
                  <div class="media-body">
                    <p class="mb-2">ID: {{company.id}}</p>
                    <p class="mb-2"><b>{{company.data().inReview == true ? 'In Review ':'ACTIVE'}}</b> <small style="color:red;">{{company.data().lastReview}}</small></p>
                  </div>
                  <a @click="copyURL(company.id)" class="text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-clipboard"></i></span
                  ></a>
                </div>
                <div class="media">
                  <div class="media-body">
                    <h4 class="mb-2">{{company.data().companyName}}</h4>
                    <p class="mb-1">
                      <span><i class="fa fa-phone mr-2 text-primary"></i></span>
                      {{company.data().companyNumber}}
                    </p>
                    <p class="mb-1">
                      <span
                        ><i class="fa fa-envelope mr-2 text-primary"></i
                      ></span>
                      {{company.data().companyEmail}}
                    </p>
                  </div>

                  <router-link :to="{ name: 'EditCompany', params: { company: company }}" class="facebook text-center"
                    ><span><i class="fa fa-edit"></i></span
                  ></router-link>
                </div>

                <ul class="card-profile__info">
                  <li>
                    <h5 class="mr-4">Receive Link</h5>
                    <u>
                       <a target=”_blank” class="text-muted" :href="`${domain}${company.id}`">{{domain}}{{company.id}}</a>
                    </u>
                  </li>
                  <br />
                  <!-- Download QR Code Button -->
                  <li>
                    <u>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-rounded"
                        @click="generateQRCode(company.id)"  
                      >
                        Download QR Code
                      </button>
                    </u>
                  </li>
                  <br />

                  <li>
                    <h5 class="mr-4">Address</h5>
                    <span class="text-muted"
                      >{{company.data().permanentaddress}}</span
                    >
                  </li>
                  <li class="mb-1">
                    <h5 class="mr-4">Totals</h5>
                    <span>{{company.data().totalTrans}} Transactions | ${{company.data().balance}} XCD</span>
                  </li>
                  <br />
                </ul>
                <div class="media-body">
                    <h4 class="mb-2">For Developers</h4>
                    <p class="mb-1">
                     Use this along with your company ID to configure your app or website payments.
                    </p>
                    <div class="row">
                      <div class="col-xl-3">
                        <h5 class="mr-4">Public Key</h5>
                      </div>
                      <div class="col-xl-7">
                        <span class="text-muted"
                      ><code>{{company.data().publicKey}}</code></span
                    >
                      </div>
                      <div class="col-xl-2">
                        <a @click="copyURL(company.data().publicKey)" class="text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-clipboard"></i></span
                  ></a>
                      </div>
                </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/dashboard/Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "Accounts",
  components: {
    Layout,
  },
  data() {
    return {
      domain: "https://payments.getitapp.co/PayCompany/",
    };
  },
  computed: {
    ...mapGetters({
      companies: "companies",
    }),
  },
  methods: {
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(
          mytext.replace(/(\r\n|\n|\r)/gm, "").replaceAll("- ", "-").replaceAll(" -", "-")
        );
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    async generateQRCode(companyId) {
      const companyLink = `${this.domain}${companyId}`;

      // Install qrcode library (assuming npm): npm install qrcode
      const qrcode = await import("qrcode"); 

      // Generate QR Code data
      await qrcode.toDataURL(companyLink, {
        type: "png",
        error: "H",
        scale: 8,
        width: 1500,
        lightColor: "#FFFFFF",
        darkColor: "#000000",
      }, function (err, url) {
        if (err) {
          alert("An Unknown error occured, please try again");
          console.err("Error generating QR Code:", err);
          return;
        }
        
        const link = document.createElement('a');
        link.href = url;
        link.download = 'qr-code.png';

        link.click();
    });
  },
  },
};
</script>