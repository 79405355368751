//--- Table Content ---//
/*
Page: SignUp
Page: SignIn
Page: Home

Component: Welcome user

Database: firebase Collections

*/

import { BootstrapVue, DropdownPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import "./assets/css/custome.css";
import "./assets/css/popup.css";
import "./assets/css/style.css";
import "./components/validation/errorMessage";
import router from "./router";
import store from "./store";
import money from 'v-money';
// import vueCrypt from 'vue-crypt';
// import "./data/firebaseinits";

Vue.use(money, {precision: 4});
Vue.use(VueCookies);
Vue.use(DropdownPlugin);
Vue.use(BootstrapVue);
// Vue.use(VeeValidate);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
