<template>
  <div v-if="sendError == 'Approved'">
    <div class="verification section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-5 col-md-6">
            <div class="auth-form card">
              <div class="card-body">
                <div class="identity-content">
                  <span class="icon"><i class="fa fa-check"></i></span>
                  <h4>
                    Congratulations. Your Payment Was Sent To
                    <b>{{ companyName }}</b> !
                  </h4>
                  <p>
                    Amount: <b>${{ amount }} {{ currency }}</b>
                    <br />
                    Trans #: <b>{{ TranId }}</b>
                    <br />
                    Invoice #: <b>{{ Invoice }}</b>
                    <br />
                    Result Code: <b>{{ transResultCode }}</b>
                  </p>
                  <p>
                    <button
                      @click="finishThing"
                      class="btn btn-success pl-5 pr-5"
                    >
                      Close
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>

  <div v-else id="main-wrapper pt-0">
    <div class="wrapper" id="app">
      <div class="card-form">
        <div class="card-list">
          <div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
            <div class="card-item__side -front">
              <div
                class="card-item__focus"
                v-bind:class="{ '-active': focusElementStyle }"
                v-bind:style="focusElementStyle"
                ref="focusElement"
              ></div>
              <div class="card-item__cover">
                <img
                  v-bind:src="
                    'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                    currentCardBackground +
                    '.jpeg'
                  "
                  class="card-item__bg"
                />
              </div>

              <div class="card-item__wrapper">
                <div class="card-item__top">
                  <img
                    src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                    class="card-item__chip"
                  />
                  <div class="card-item__type">
                    <transition name="slide-fade-up">
                      <img
                        v-bind:src="
                          'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                          getCardType +
                          '.png'
                        "
                        v-if="getCardType"
                        v-bind:key="getCardType"
                        alt=""
                        class="card-item__typeImg"
                      />
                    </transition>
                  </div>
                </div>
                <label
                  for="cardNumber"
                  class="card-item__number"
                  ref="cardNumber"
                >
                  <template v-if="getCardType === 'amex'">
                    <span v-for="(n, $index) in amexCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div
                          class="card-item__numberItem"
                          v-if="
                            $index > 4 &&
                            $index < 14 &&
                            cardNumber.length > $index &&
                            n.trim() !== ''
                          "
                        >
                          *
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          :key="$index"
                          v-else-if="cardNumber.length > $index"
                        >
                          {{ cardNumber[$index] }}
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                        >
                          {{ n }}
                        </div>
                      </transition>
                    </span>
                  </template>

                  <template v-else>
                    <span v-for="(n, $index) in otherCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div
                          class="card-item__numberItem"
                          v-if="
                            $index > 4 &&
                            $index < 15 &&
                            cardNumber.length > $index &&
                            n.trim() !== ''
                          "
                        >
                          *
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          :key="$index"
                          v-else-if="cardNumber.length > $index"
                        >
                          {{ cardNumber[$index] }}
                        </div>
                        <div
                          class="card-item__numberItem"
                          :class="{ '-active': n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                        >
                          {{ n }}
                        </div>
                      </transition>
                    </span>
                  </template>
                </label>
                <div class="card-item__content">
                  <label for="cardName" class="card-item__info" ref="cardName">
                    <div class="card-item__holder">Card Holder</div>
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__name"
                        v-if="cardName.length"
                        key="1"
                      >
                        <transition-group name="slide-fade-right"
                          ><!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                          <span
                            class="card-item__nameItem"
                            v-for="(n, $index) in cardName.replace(
                              /\s\s+/g,
                              ' '
                            )"
                            v-if="$index === $index"
                            v-bind:key="$index + 1"
                            >{{ n }}</span
                          >
                        </transition-group>
                      </div>
                      <div class="card-item__name" v-else key="2">
                        Full Name
                      </div>
                    </transition>
                  </label>
                  <div class="card-item__date" ref="cardDate">
                    <label for="cardMonth" class="card-item__dateTitle"
                      >Expires</label
                    >
                    <label for="cardMonth" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardMonth" v-bind:key="cardMonth">{{
                          cardMonth
                        }}</span>
                        <span v-else key="2">MM</span>
                      </transition>
                    </label>
                    /
                    <label for="cardYear" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardYear" v-bind:key="cardYear">{{
                          String(cardYear).slice(2, 4)
                        }}</span>
                        <span v-else key="2">YY</span>
                      </transition>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-item__side -back">
              <div class="card-item__cover">
                <img
                  v-bind:src="
                    'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                    currentCardBackground +
                    '.jpeg'
                  "
                  class="card-item__bg"
                />
              </div>
              <div class="card-item__band"></div>
              <div class="card-item__cvv">
                <div class="card-item__cvvTitle">CVV</div>
                <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in cardCvv" :key="$index"> * </span>
                </div>
                <div class="card-item__type">
                  <img
                    v-bind:src="
                      'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                      getCardType +
                      '.png'
                    "
                    v-if="getCardType"
                    class="card-item__typeImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-form__inner">
          <ValidationObserver v-slot="{ passes }">
            <form
              method="post"
              name="myform"
              novalidate="novalidate"
              @submit.prevent="passes(formSubmit)"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="method"
                class="form-group"
                tag="div"
              >
            
                <div class="card-form__row">
                  <div class="card-form__col">
                    <div class="card-input">
                      <label for="amountTxt" class="card-input__label"
                        >Amount To Be Paid To <b>{{ companyName }}</b>
                      </label>
                      <money
                        class="card-input__input"
                        autocomplete="off"
                        id="amountTxt"
                        v-model="amount"
                        v-bind="money"
                      ></money>
                    </div>
                    <label class="error card-input__label" for="amountTxt">{{
                      errors[0]
                    }}</label>
                    <label class="card-input__label">1 XCD = 0.37 USD</label>
                  </div>
                  <div class="card-form__col">
                    <div class="card-input">
                      <label for="cardCurrency" class="card-input__label"
                        >Currency</label
                      >
                      <select
                        v-model="currency"
                        class="card-input__input -select"
                        id="cardCurrency"
                      >
                        <!-- <option value="" disabled selected>Year</option> -->
                        <option value="XCD">XCD</option>
                        <!-- <option value="USD">
                    USD
                </option> -->
                      </select>
                    </div>
                  </div>
                </div>
                <div class="card-input">
                  <label for="personName" class="card-input__label"
                    >Full Name</label
                  >
                  <input
                    type="text"
                    id="personName"
                    class="card-input__input"
                    v-model="personName"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="personName"
                    autocomplete="off"
                    required
                  />
              </div>
                <div class="card-form__row">
                  <div class="card-form__col">
                    <div class="card-input">
                  <label for="personPhone" class="card-input__label"
                    >Phone Number</label
                  >
                  <input
                    type="tel"
                    id="personPhone"
                    class="card-input__input"
                    v-model="personPhone"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="personPhone"
                    autocomplete="off"
                    required
                  />
                  <label class="error card-input__label" for="personPhone">{{
                    
                  }}</label>
              </div>
                  </div>
                  <div class="card-form__col">
                    <div class="card-input">
                  <label for="personEmail" class="card-input__label"
                    >Email</label
                  >
                  <input
                    type="email"
                    id="personEmail"
                    class="card-input__input"
                    v-model="personEmail"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="personEmail"
                    autocomplete="off"
                    required
                  />
              </div>
                  </div>
                </div>
              </ValidationProvider>
              <div class="card-input">
                <ValidationProvider
              rules="required"
                v-slot="{ errors }"
                name="method"
                class="form-group"
                tag="div">
                <div class="card-form__col">
                  <label for="perposeTxt" class="card-input__label"
                        >Payment Description
                      </label>
                      <textarea v-model="purposeTxt" placeholder="Enter there purpose of this payment. 30 char max." class="card-input__input" id="perposeTxt" name="perposeTxt" maxlength="30" rows="4" cols="50">
</textarea>
<label class="error card-input__label">{{
                        errors[0]
                      }}</label>
                </div>
              </ValidationProvider>
              </div>
              
              <div v-if="showCarib">
                <div class="card-form__row">
                  <div class="card-input" style="50%; margin-right: 5%">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      name="method"
                      class="form-group"
                      tag="div"
                    >
                      <label for="country" class="card-input__label"
                        >Country</label
                      >
                      <input
                        type="text"
                        id="country"
                        class="card-input__input"
                        v-model="country"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="country"
                        autocomplete="off"
                      />
                      <label class="error card-input__label" for="phone">{{
                        errors[0]
                      }}</label>
                    </ValidationProvider>
                  </div>
                  <div class="card-input" style="width: 50%">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      name="method"
                      class="form-group"
                      tag="div"
                    >
                      <label for="phone" class="card-input__label">Phone</label>
                      <input
                        type="number"
                        id="phone"
                        class="card-input__input"
                        v-model="phone"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="phone"
                        autocomplete="off"
                      />
                      <label class="error card-input__label" for="phone">{{
                        errors[0]
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="card-input">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="method"
                    class="form-group"
                    tag="div"
                  >
                    <label for="email" class="card-input__label">Email</label>
                    <input
                      type="email"
                      id="email"
                      class="card-input__input"
                      v-model="email"
                      v-on:focus="focusInput"
                      v-on:blur="blurInput"
                      data-ref="email"
                      autocomplete="off"
                    />
                    <label class="error card-input__label" for="phone">{{
                      errors[0]
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
              <div class="card-input">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="method"
                  class="form-group"
                  tag="div"
                >
                  <label for="cardNumber" class="card-input__label"
                    >Card Number</label
                  >
                  <input
                    type="text"
                    id="cardNumber"
                    class="card-input__input"
                    v-mask="generateCardNumberMask"
                    v-model="cardNumber"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardNumber"
                    autocomplete="off"
                    required
                  />
                  <label class="error card-input__label" for="cardNumber">{{
                    errors[0]
                  }}</label>
                </ValidationProvider>
              </div>
              <div class="card-input">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="method"
                  class="form-group"
                  tag="div"
                >
                  <label for="cardName" class="card-input__label"
                    >Card Holder</label
                  >
                  <input
                    type="text"
                    id="cardName"
                    class="card-input__input"
                    v-model="cardName"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardName"
                    autocomplete="off"
                    required
                  />
                  <label class="error card-input__label" for="cardName">{{
                    errors[0]
                  }}</label>
                </ValidationProvider>
              </div>
              <div class="card-form__row">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="method"
                  class="form-group"
                  tag="div"
                >
                  <div class="card-form__col">
                    <div class="card-form__group">
                      <label for="cardMonth" class="card-input__label"
                        >Expiration Date</label
                      >
                      <select
                        class="card-input__input -select"
                        id="cardMonth"
                        v-model="cardMonth"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Month</option>
                        <option
                          v-bind:value="n < 10 ? '0' + n : n"
                          v-for="n in 12"
                          v-bind:disabled="n < minCardMonth"
                          v-bind:key="n"
                        >
                          {{ calThing(n) }}
                        </option>
                      </select>

                      <select
                        class="card-input__input -select"
                        id="cardYear"
                        v-model="cardYear"
                        v-on:focus="focusInput"
                        v-on:blur="blurInput"
                        data-ref="cardDate"
                      >
                        <option value="" disabled selected>Year</option>
                        <option
                          v-bind:value="$index + minCardYear"
                          v-for="(n, $index) in 12"
                          v-bind:key="n"
                        >
                          {{ $index + minCardYear }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <label class="error card-input__label" for="cardMonth">{{
                    errors[0]
                  }}</label>

                  <label class="error card-input__label" for="cardYear">{{
                    errors[0]
                  }}</label>
                </ValidationProvider>
                <div class="card-form__col -cvv">
                  <div class="card-input">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                      name="method"
                      class="form-group"
                      tag="div"
                    >
                      <label for="cardCvv" class="card-input__label">CVV</label>
                      <input
                        type="text"
                        class="card-input__input"
                        id="cardCvv"
                        v-mask="'####'"
                        maxlength="4"
                        v-model="cardCvv"
                        v-on:focus="flipCard(true)"
                        v-on:blur="flipCard(false)"
                        autocomplete="off"
                      />
                      <label class="error card-input__label" for="cardCvv">{{
                        errors[0]
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <label
                style="text-align: center; font-size: 20px; color: red"
                class="card-input__label"
                >{{ sendError }}</label
              >
              <div v-if="isProcessing" style="text-align: center">
                <b-spinner label="Spinning"></b-spinner>
              </div>

              <button v-else class="card-form__button">Submit</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import { useCurrencyInput } from 'vue-currency-input';
// import NodeRSA from 'node-rsa'
import { mask } from "vue-the-mask";
import "../assets/css/new_style.scss";
import Footer from "../components/landing/Footer.vue";
import { Money } from "v-money";
const NodeRSA = require("node-rsa");

import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "PayCompany",
  components: {
    Money,
    Footer,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
  },
  data() {
    return {
      phone: null,
      email: null,
      country: null,
      sendError: "",
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      purposeTxt: "",
      personPhone: "",
      personEmail: "",
      personName: "",
      companyName: "",
      companyId: "",
      pKey: "",
      amount: 0.0,
      currency: "XCD",
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      isProcessing: false,
      transResultCode: "",
      TranId: "",
      Invoice: "",
    };
  },
  mounted() {
    this.getInitialData();
    this.cardNumberTemp = this.otherCardMask;
    // document.getElementById("cardNumber").focus();
    if (this.$route.path.includes("payment")) {
      this.amount = 99;
    }
  },
  computed: {
    showCarib() {
      return this.$route.path.includes("payment") ? true : false;
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
  },
  methods: {
    finishThing() {
      this.$router.push("/");
    },
    sendMail() {
      this.showCarib = false;
      if (this.showCarib) {
        let api_key = "9be94dfeff096b882957ba20d7176d8a-8d821f0c-99047cf9";

        axios({
          method: "post",
          url: "https://api.mailgun.net/v3/mg.getitapp.co/messages",
          auth: {
            username: "api",
            password: api_key,
          },
          params: {
            from: "Caribbean Properties <PropertyInvesting@gmail.com>",
            to: this.email,
            template: "thank_you",
            subject: "Payment Successful | Caribbean Properties",
          },
        }).then(
          (response) => {
            console.log(response);
          },
          (reject) => {
            console.log(reject);
          }
        );
        axios({
          method: "post",
          url: "https://api.mailgun.net/v3/mg.getitapp.co/messages",
          auth: {
            username: "api",
            password: api_key,
          },
          params: {
            from: "Caribbean Properties <PropertyInvesting@gmail.com>",
            to: this.email,
            template: "carib_prop_user_info",
            subject: "Payment Successful | Caribbean Properties",
            "h:X-Mailgun-Variables": {
              userName: this.cardName,
              userEmail: this.email,
              userCountry: this.country,
              userPhone: this.phone,
              link: "https://www.caribbeanpropertyinvesting.com/",
            },
          },
        }).then(
          (response) => {
            console.log(response);
          },
          (reject) => {
            console.log(reject);
          }
        );
      } else return;
    },
    async getInitialData() {
      //   console.log(this.$route.params.id)
      if (this.$route.params.id) {
        await axios
          .post(
            "https://us-central1-getitpayments.cloudfunctions.net/getCompanyData",
            { companyId: this.$route.params.id }
          )
          .then((response) => {
            //   console.log(response.data)
            var status = response.data.status;
            if (status == "success") {
              this.pKey = response.data.publicKey;
              this.companyName = response.data.companyName;
              this.companyId = this.$route.params.id;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async sendData(encryptedData) {
      await axios
        .post(
          "https://us-central1-getitpayments.cloudfunctions.net/webPay",
          encryptedData
        )
        .then((response) => {
          let mResponse = response.data;
          console.log(mResponse);
          if (typeof mResponse.msg == "string") {
            this.sendError = mResponse.msg;
          } else {
            if (mResponse.msg.ResultCode) {
              this.transResultCode = mResponse.msg.ResultCode[0];
            }
            if (mResponse.msg.FinancialResultCode) {
              this.sendError = mResponse.msg.FinancialResultCode[0];
              // this.sendMail();
            } // if it says Approved then you good.
            if (mResponse.msg.ErrorMessage) {
              this.sendError = "ERROR - " + mResponse.msg.ErrorMessage[0];
            }
            if (mResponse.msg.TranId) {
              this.TranId = mResponse.msg.TranId[0];
            }
            if (mResponse.msg.Invoice) {
              this.Invoice = mResponse.msg.Invoice[0];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.sendError = error;
        });
      this.isProcessing = false;
    },
    encryptedData() {
      const key = new NodeRSA(this.pKey);
      key.setOptions({ encryptionScheme: "pkcs1" });
      const withoutFirst2 = this.cardYear.toString().slice(2);
      const payInfo = {
        name: this.cardName,
        amount: this.amount.toString(),
        currency: this.currency,
        month: this.cardMonth.toString(),
        year: withoutFirst2,
        cvv: this.cardCvv,
        cardNumber: this.cardNumber.replaceAll(" ", ""),
        purpose: this.purposeTxt.trim(),
        personEmail: this.personEmail.trim(),
        personPhone: this.personPhone.trim(),
        personName: this.personName.trim()
      };
      const encrypted = key.encrypt(payInfo, "base64");
      var payLoad = {
        testMode: "0",
        companyId: this.companyId,
        encryptedTxt: encrypted,
      };
      this.sendData(payLoad);
    },
    formSubmit() {
      if(this.cardName == '' || this.personName == '' || this.purposeTxt == '' || this.cardNumber == '' || this.personEmail == '' || this.personPhone == ''){
        return;
      }
      console.log("processing...");
      this.isProcessing = true;
      this.encryptedData();
    },
    calThing(n) {
      return n < 10 ? "0" + n : n;
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
};
</script>

<style></style>
