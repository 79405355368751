var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"market section-padding page-section",attrs:{"id":"market","data-scroll-index":"1"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"market-table"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-0 table-responsive-sm"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_vm._m(2),_c('td',[_vm._v(" USD 680,175.06 ")]),_vm._m(3),_c('td',{attrs:{"id":"sparkLink"}},[_c('market-sparkline')],1)]),_c('tr',[_c('td',[_vm._v("2")]),_vm._m(4),_c('td',[_vm._v(" USD 680,175.06 ")]),_vm._m(5),_c('td',{attrs:{"id":"sparkLink"}},[_c('market-sparkline')],1)]),_c('tr',[_c('td',[_vm._v("3")]),_vm._m(6),_c('td',[_vm._v(" USD 680,175.06 ")]),_vm._m(7),_c('td',{attrs:{"id":"sparkLink"}},[_c('market-sparkline')],1)]),_c('tr',[_c('td',[_vm._v("4")]),_vm._m(8),_c('td',[_vm._v(" USD 680,175.06 ")]),_vm._m(9),_c('td',{attrs:{"id":"sparkLink"}},[_c('market-sparkline')],1)])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row py-lg-5 justify-content-center"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"section_heading"},[_c('span',[_vm._v("Your Dashboard")]),_c('h3',[_vm._v("A dashboard which gives you all the information you need. When you need it")]),_c('p',[_vm._v(" Merchants can see all their sales receipts and invoices all in one place. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Account")]),_c('th',[_vm._v("Earned")]),_c('th',[_vm._v("Change")]),_c('th',[_vm._v("Chart")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"coin_icon"},[_c('span',[_vm._v("Castries branch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"text-success"},[_vm._v("+1.13%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"coin_icon"},[_c('span',[_vm._v("Gros Islet Branch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"text-success"},[_vm._v("+1.13%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"coin_icon"},[_c('span',[_vm._v("Food truck")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"text-success"},[_vm._v("+1.13%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"coin_icon"},[_c('span',[_vm._v("Hardware Store")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"text-danger"},[_vm._v("-0.47%")])])
}]

export { render, staticRenderFns }