// Page: Home
<template>
  <layout :active="1" :title="true">
    <!-- <toster /> -->
    <div v-if="loadingData" class="content-body">
      <div class="verification section-padding">
        <div class="container h-100">
          <div class="row justify-content-center h-100 align-items-center">
            <div class="col-xl-5 col-md-6">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content-body">
      <div class="container">
        <div v-if="userData.primaryCompany && hasBank" class="row">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Last Month</h5>
                    <p class="text-success">
                      <span>_</span>
                    </p>
                  </div>
                  <div class="widget-info">
                    <h3>${{previousMonthTotal.toFixed(2)}}</h3>
                    <!-- <p>USD</p> -->
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>This Month</h5>
                    <p v-if="thisMonthTotal < previousMonthTotal" class="text-danger">
                      {{((thisMonthTotal - previousMonthTotal) / previousMonthTotal * 100).toFixed(1) }}% <span><i class="las la-arrow-down"></i></span>
                    </p>
                     <p v-else class="text-success">
                      {{((thisMonthTotal - previousMonthTotal) / previousMonthTotal * 100).toFixed(1) }}%  <span><i class="las la-arrow-up"></i></span>
                    </p>
                  </div>
                  <div class="widget-info">
                    <h3>${{thisMonthTotal.toFixed(2)}}</h3>
                    <!-- <p>USD</p> -->
                  </div>
                </div>
              </div>
              <!-- <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Balance</h5>
                    <p class="text-success">
                      133% <span><i class="las la-arrow-up"></i></span>
                    </p>
                  </div>
                  <div class="widget-info">
                    <h3>$18,235.0</h3>
                    <p>USD</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Balance</h5>
                    <p class="text-danger">
                      133% <span><i class="las la-arrow-down"></i></span>
                    </p>
                  </div>
                  <div class="widget-info">
                    <h3>$18,235.0</h3>
                    <p>USD</p>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-xl-8 col-lg-12 col-xxl-8">
                <div class="card profile_chart transparent">
                  <div class="card-header">
                    <div class="chart_current_data">
                      <small>Total transactions</small>
                      <h3>{{previousMonth.length + thisMonth.length}} <span></span></h3>
                      <!-- <h3>254856 <span>USD</span></h3> -->
                      <!-- <p class="text-success">125648 <span>USD (20%)</span></p> -->
                    </div>
                    <div class="duration-option">
                      <a
                        id="all"
                        class="c-pointer"
                        @click="active = 'all'"
                        :class="active === 'all' && 'active'"
                        >ALL</a
                      >
                      <a
                        id="one_month"
                        class="c-pointer"
                        @click="active = '1m'"
                        :class="active === '1m' && 'active'"
                        >1M</a
                      >
                      <a
                        id="six_months"
                        class="c-pointer"
                        @click="active = '6m'"
                        :class="active === '6m' && 'active'"
                        >6M</a
                      >
                      <a
                        id="one_year"
                        class="c-pointer"
                        @click="active = '1y'"
                        :class="active === '1y' && 'active'"
                        >1Y</a
                      >
                      <a
                        id="ytd"
                        class="c-pointer"
                        @click="active = 'ytm'"
                        :class="active === 'ytm' && 'active'"
                        >YTD</a
                      >
                    </div>
                  </div>
                  <div v-if="isLoading == false" class="card-body">
                    <!-- <div id="timeline-chart"></div> -->
                    <TimelineChart :previousTrans="previousMonth" :thisTrans="thisMonth" />
                  </div>
                  <div v-else>nothing</div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-12 col-xxl-4">
                <div class="card balance-widget transparent">
                  <div class="card-body">
                    <!-- <div id="wallet-chart"></div> -->
                    <!-- <wallet-chart /> -->
                    <div class="balance-widget mt-5">
                      <h4>Company Balances : <strong v-if="companies[0]">${{companies[0].balance}}</strong><strong v-else>$0.00</strong></h4>
                      <ul v-for="company in companies" :key="company.id" class="list-unstyled">
                        <li class="media">
                          <i class="fa fa-building mr-3"></i>
                          <div class="media-body">
                            <h5 class="m-0">{{company.data().companyName}}</h5>
                          </div>
                          <div class="text-right">
                            <h5>${{company.data().balance}}</h5>
                            <span>${{company.data().lastTransactionTotal}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="row">
              <!-- <div class="col-xl-6 col-lg-12 col-xxl-4">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Exchange</h4>
                  </div>
                  <div class="card-body">
                    <currency-form />
                  </div>
                </div>
              </div> -->
              <div class="col-xl-6 col-lg-12 col-xxl-8">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Recent Transactions</h4>
                  </div>
                  <div class="card-body">
                    <div class="transaction-widget">
                      <ul v-for="singleTrans in thisMonth" :key="singleTrans.id" class="list-unstyled">
                        <li class="media">
                          <span v-if="singleTrans.status == 'Approved'" class="buy-thumb"
                            ><i class="la la-arrow-up"></i
                          ></span>
                          <span v-else class="sold-thumb"
                            ><i class="la la-exclamation-triangle"></i
                          ></span>
                          <div class="media-body">
                            <p>
                              <small>{{customFormatter(singleTrans.created.toDate())}} </small>
                             
                            </p>
                            <p v-if="singleTrans.response" class="wallet-address text-dark">
                              Trans Id: {{singleTrans.response.TranId[0]}}
                            </p>
                            <p v-if="singleTrans.response" class="wallet-address text-dark">
                              Invoice No.: {{singleTrans.response.Invoice[0]}}
                            </p>
                          </div>
                          <div class="media-body">
                            <p>
                              <small>**** **** **** {{singleTrans.lastFour}}</small>
                            </p>
                            <p class="wallet-address text-dark">Saint Lucia</p>
                          </div>
                          <div class="text-right">
                            <h4 v-if="singleTrans.response">${{singleTrans.response.Amount[0]}} {{singleTrans.response.Currency[0]}}</h4>
                            <small v-if="singleTrans.status">{{singleTrans.status}}</small>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Get started with GetIt Payments</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-xl-3 col-lg-6 col-md-6"
                    style="text-align: center"
                  >
                  <router-link :to=" authUser.emailVerified ?'#':'/verify-email'">
                    <i
                        class="fa fa-envelope fa-lg"
                        style="padding-bottom: 10px"
                      ></i>
                      <h5>Verify Account</h5>
                      <p>
                        Please check your email for your verification email.
                      </p>
                      <div v-if="authUser.emailVerified " class="verified">
                        <span><i class="la la-check"></i></span>
                        Verified
                      </div>
                      <i v-else class="la la-arrow-right"></i>

                  </router-link>
                  </div>
                  <div
                    class="col-xl-3 col-lg-6 col-md-6"
                    style="text-align: center"
                  >
                  <router-link :to="userData.primaryCompany ? '#':'/edit-company'">
                    <i
                        class="fa fa-building fa-lg"
                        style="padding-bottom: 10px"
                      ></i>
                      <h5>Setup your company</h5>
                      <p>You can add multiple companies into GetIt Payments.</p>
                       <div v-if="userData.primaryCompany" class="verified">
                        <span><i class="la la-check"></i></span>
                        Created
                      </div>
                      <i v-else class="la la-arrow-right"></i>
                  </router-link>
                  </div>
                  <div
                    class="col-xl-3 col-lg-6 col-md-6"
                    style="text-align: center"
                  >
                    <router-link to="/accounts">
                      <i
                        class="fa fa-bank fa-lg"
                        style="padding-bottom: 10px"
                      ></i>
                      <h5>Link Bank Account</h5>
                      <p>
                        This is the bank account your funds will be transfered
                        to once a week.
                      </p>
                      <div v-if="bankDetails.id" class="verified">
                        <span><i class="la la-check"></i></span>
                        {{bankDetails.data().inReview ? 'In Review': 'Created'}}
                        <small style="color:red;">{{bankDetails.data().reviewTxt}}</small>
                      </div>
                      <i v-else class="la la-arrow-right"></i>
                      
                    </router-link>
                  </div>
                  <div
                    class="col-xl-3 col-lg-6 col-md-6"
                    style="text-align: center"
                  >
                    <router-link to="/devdocs">
                      <i
                        class="fa fa-plug fa-lg"
                        style="padding-bottom: 10px"
                      ></i>
                      <h5>Setup On Web or App</h5>
                      <p>
                        GetIt Payments can be setup on web or on mobile. Select
                        the option that works for you.
                      </p>
                      <i class="la la-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import TimelineChart from "../../components/dashboard/charts/TimelineChart.vue";
// import CurrencyForm from "../../components/CurrencyForm";
import Layout from "../../components/dashboard/Layout.vue";
// import WalletChart from "../../components/dashboard/charts/WalletChart.vue";
// import BtcChart from "../../components/dashboard/charts/BtcChart.vue";
// import Toster from "../../components/dashboard/Toster.vue";
import { bankAccountCol } from "../../data/firebaseDatabase";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Index",
  components: {
    TimelineChart,
    // CurrencyForm,
    Layout,
    // WalletChart,
    // BtcChart,
    // Toster,
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      previousMonth: 'previousMonth',
      previousMonthTotal: 'previousMonthTotal',
      thisMonth: 'thisMonth',
      thisMonthTotal: 'thisMonthTotal',
      companies: 'companies',
      isLoading: 'isLoading'
    }),
    
  },
  data() {
    return {
      loadingData: true,
      hasBank: false,
      bankDetails: {},
      active: "all",
      authUser: JSON.parse(sessionStorage.getItem("authUser"))
    };
  },
  methods: {
    ...mapActions([ "sendVerification"]),
customFormatter(date) {
      return moment(date).format(" DD MM YYYY h:mm a");
    },
    async getBankInfo(){
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      await bankAccountCol.doc(authUser.uid)
      .get().then((bankData)=>{
        if(bankData.exists){
          this.bankDetails = bankData;
          if(bankData.data().inReview == true){
this.hasBank = false;
          }else{
            this.hasBank = true;
          }
          
        }
        this.loadingData = false;
      })
    }
  },
  mounted(){
    this.getBankInfo();
  }
};
</script>
