// Database: firebase Collections
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
//Fix firebase initalize error
import "@/data/firebaseinits";

const db = firebase.firestore();
// const mode = "~test_";
const mode = "";

export const salesCollection = db.collection(`${mode}sales`);
export const testSalesCollection = db.collection(`test_sales`);
export const companiesCollection = db.collection(`${mode}companys`);
export const usersCollection = db.collection(`${mode}users`);
export const secureDataCollection = db.collection(`${mode}secure_data`);
export const companyReportCollection = db.collection(`${mode}companyReports`);
export const bankAccountCol = db.collection(`${mode}bankAccount`);
export const payoutsCol = db.collection(`${mode}payouts`);
export const settingsCol = db.collection(`${mode}settings`);
