//Component: Welcome user
<template>
  <div class="page_title">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="page_title-content">
            <p style="text-align: left" v-if="userData.primaryCompany">
              Welcome Back,
              <span> {{ userData.fName }} {{ userData.lName }}</span>
            </p>
            <p style="text-align: left" v-else>
              Welcome,
              <span> {{ userData.fName }} {{ userData.lName }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PageTitle",
  computed: {
    ...mapGetters({
      userData: 'userData',
    }),
  },
  data() {
    return {
      authUser: {},
    };
  },
  methods: {
    getAuthenticatedUser() {
      if (!sessionStorage.getItem("authUser")) return null;
      this.authUser = JSON.parse(sessionStorage.getItem("authUser"));
      console.log(this.authUser);
    },
  },
  mounted() {
    this.getAuthenticatedUser();
  },
};
</script>
