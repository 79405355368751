<template>
  <div id="main-wrapper" class="show">
    <Nav />
    <div class="page_title pt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-sm-6 col-6">
            <div class="page_title-content">
              <p>Last updated: October 21, 2020</p>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-6 text-sm-left">
            <ul class="text-right breadcrumbs list-unstyle">
              <!-- <li><a href="./settings.html">Settings </a></li> -->
              <li class="active"><a href="#">About Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="about py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9">
            <div class="about_content">
              <div class="our_vision">
                <h3>OUR VISION</h3>
                <p>
                  <b>Simplify money transfer from customer to business online.</b>
                </p>
                <p>
                  Get it Payments main focus is to remove stumbling blocks that may exist when businesses 
                  need to collect payments via an online medium.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "../components/landing/Footer";
import Nav from "../components/landing/Nav";
export default {
  name: "About",
  components: {
    Footer,
    Nav,
  },
};
</script>
