// Place file in - src > store > modules > firebaseCRUD.js
// This file depends on the following files:
//  /dashboard/src/data/fireBaseDatabase.js
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { usersCollection, companiesCollection, salesCollection } from "@/data/firebaseDatabase";
//state - just variables and constants.
const state = {
  isLoading: true,
  userData: {},
  companies: [],
  companyOptions: [],
  selectedCompany: 'new',
  previousMonth: [],
  previousMonthTotal: 0,
  thisMonth: [],
  thisMonthTotal: 0
};
// getters - gets data from state
const getters = {
  isLoading: state => state.isLoading,
  userData: state => state.userData,
  companies: state => state.companies,
  companyOptions: state => state.companyOptions,
  selectedCompany: state => state.selectedCompany,
  previousMonth: state => state.previousMonth,
  previousMonthTotal: state => state.previousMonthTotal,
  thisMonth: state => state.thisMonth,
  thisMonthTotal: state => state.thisMonthTotal
};
//actions - for running async code before mutations
// the context is not refering to the exact state but it can be used
// in same way
const actions = {
  // C - Create data
  async createData(context, data) {
    await firebase
      .firestore()
      .collection(data.collectionName)
      .add(data.collectionData)
      .then(() => {
        return true;
      });
  },
  async getHomeReports(context){
    
// let end = new Date();
let today = new Date();
// let start = today.setDate(0);
// var month = 0; // January
var month = today.getMonth();
let previousStart = new Date(today.getFullYear(), month - 1, 1);
// const expirationDate = firebase.firestore.Timestamp.fromDate(previousStart);

let previousEnd = new Date(today.getFullYear(), month, 0);
// const expirationDate2 = firebase.firestore.Timestamp.fromDate(previousEnd);
// console.log("today", today);
// console.log(previousEnd);
    if(state.userData.primaryCompany){
      var prevTrans = [];
      var prevSum = 0;
      // var nowTrans = [];
      await salesCollection.where("companyId", "==", state.userData.primaryCompany)
      .where("created", '>', previousStart).where("created", '<', previousEnd).get().then((salesData)=>{
        console.log("salesData.size ", salesData.size);
        if(salesData.size > 0){
          salesData.docs.forEach((singleSale)=>{
            prevSum += parseFloat(singleSale.data().amount)
            var data = {
              id: singleSale.id,
              ...singleSale.data()
            }
            prevTrans.push(data);
          });
          
          context.commit('updatePreviousMonth', prevTrans);
          context.commit('updatePreviousMonthTotal', prevSum);
        }
      })
      // now:
      var nowTrans = [];
      var nowSum = 0;
      await salesCollection.where("companyId", "==", state.userData.primaryCompany)
      .where("created", '>', previousEnd).where("created", '<', today).orderBy("created", "desc").get().then((salesData)=>{
        console.log("salesData.size ", salesData.size);
        if(salesData.size > 0){
          salesData.docs.forEach((singleSale)=>{
            if(singleSale.data().status == "Approved"){
              nowSum += parseFloat(singleSale.data().amount)
            }
            var data = {
              id: singleSale.id,
              ...singleSale.data()
            }
            nowTrans.push(data);
            
            
          });
          
          context.commit('updateNowMonth', nowTrans);
          context.commit('updateNowMonthTotal', nowSum);
        }
      })
      context.commit('updateLoading', false);
    }
  },
  // R - Read data
  async fetchUserData(context){
    console.log("fetching");
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    const snapshot = await usersCollection.doc(authUser.uid).get();
    context.commit("setUserData", snapshot);
  },
  async changeSelectedCompany(context, companyId){
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    await usersCollection.doc(authUser.uid).update({
      primaryCompany: companyId
    });
    window.location.reload();
    // context.commit("changeSelectedCompany", companyId);
    // context.dispatch('getHomeReports');
  },

  async fetchCompanyData(context){
    var theList = [];
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    await companiesCollection
    .where("standardUsers", "array-contains", authUser.uid).get().then((allCompanies)=>{
      if(allCompanies.size > 0){
        allCompanies.docs.forEach((singleDoc)=>{
        theList.push(singleDoc);
        })
      }
    });
   context.dispatch('getHomeReports');
    context.commit("fetchCompanyData", theList);
  },

  async sendVerification(){
    // const user = await firebase.auth().currentUser;
    //   user.sendEmailVerification().then(function() {
    //     // Email sent.
    //     console.log("Email sent");
    //   }).catch(function(error) {
    //     // An error happened.
    //     console.log(error);
    //   });}
    
  },

  async fetchData(context, collectionName) {
    const snapshot = await collectionName
      .orderBy("created", "desc")
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          let docData = doc.data();
          if (doc.data().created) {
            let createdDate = doc.data().created.toDate().toDateString();
            docData.created = createdDate;
          }
          data.push({
            id: doc.id,
            ...docData,
          });
        });
        return data;
      });
    return snapshot;
  },
  async fetchDataById(context, data) {
    try {
      const snapshot = await data.collectionName
        .doc(data.id)
        .get()
        .then((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      return snapshot;
    } catch (error) {
      console.log("Error fetching data by id - ", error);
    }
  },
  async fetchDataByField(context, data) {
    try {
      const snapshot = await data.collectionName
        .where(data.field, "==", data.value)
        .get()
        .then((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            let docData = doc.data();
            if (doc.data().created) {
              let createdDate = doc.data().created.toDate().toDateString();
              docData.created = createdDate;
            }
            data.push({
              id: doc.id,
              ...docData,
            });
          });
          return data;
        });
      return snapshot;
    } catch (error) {
      console.log("Error fetching data by field - ", error);
      // console.log(error);
    }
  },
  // U - Update data
  async updateData(context, data) {
    try {
      const snapshot = await data.collectionName
        .doc(data.id)
        .update(data.data)
        .then(() => {
          return true;
        });
      return snapshot;
    } catch (error) {
      console.log("Error updating data - ", error);
    }
  },
  async updateDataByField(context, data) {
    try {
      const snapshot = data.collectionName
        .where(data.field, "==", data.value)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update(data.data);
          });
          return true;
        });
      return snapshot;
    } catch (error) {
      console.log("Error updating data by field - ", error);
    }
  },
  // D - delete data
  async deleteData(context, data) {
    try {
      await data.collectionName
        .doc(data.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    } catch (error) {
      console.log("Error deleting data - ", error);
    }
  },
};
// mutations - for changing state
const mutations = {
  updateLoading(state, data){
    state.isLoading = data;
  },
  updatePreviousMonthTotal(state, data){
    state.previousMonthTotal = data;
  },
          updateNowMonthTotal(state, data){
            state.thisMonthTotal = data;
          },
  updateNowMonth(state, data){
    state.thisMonth = data;
  },
  updatePreviousMonth(state, data){
    state.previousMonth = data;
  },
  //not in use
  changeSelectedCompany(state, data){
    state.selectedCompany = data;
  },
  setUserData(state, data){
    state.userData = data.data();
  },
  fetchCompanyData(state, data){
    var optionsList = [];
    data.forEach((mData)=>{
      optionsList.push(
        {
          value: mData.id,
          text: mData.data().companyName 
        }
      )
    });
    state.companyOptions = optionsList;
    if(optionsList.length > 0){
      state.selectedCompany = state.userData.primaryCompany
    }
    state.companies = data;

  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
