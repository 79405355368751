<template>
  <Layout :active="3" :title="true">
    <div class="content-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="card profile_card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <h4 class="mb-2">{{userData.fName}} {{userData.lName}}</h4>
                    <p class="mb-1">
                      <span><i class="fa fa-phone mr-2 text-primary"></i></span>
                      {{userData.phone}}
                    </p>
                    <p class="mb-1">
                      <span
                        ><i class="fa fa-envelope mr-2 text-primary"></i
                      ></span>
                      {{userData.email}}
                    </p>
                  </div>

                  <router-link to="/edit-account" class="facebook text-center"
                    ><span><i class="fa fa-edit"></i></span
                  ></router-link>
                </div>

                <ul class="card-profile__info">
                  <li>
                    <h5 class="mr-4">Address</h5>
                    <span class="text-muted"
                      >{{userData.country}} {{userData.address}}</span
                    >
                  </li>
                  <!-- <li class="mb-1">
                    <h5 class="mr-4">Total Log</h5>
                    <span>103 Time (Today 5 Times)</span>
                  </li> -->
                  <li>
                    <h5 class="mr-4">Developer</h5>
                    <span>
                      <router-link
                    to="/devdocs"
                    >Instructions to Configure your website or app ></router-link
                  >

                    </span>
                    
                  </li>
                  <li>
                    <h5 class="text-danger mr-4">Last Log</h5>
                    <span class="text-danger">{{lastLogin}}</span>
                  </li>
                  
                </ul>
                <!-- <div class="social-icons">
                  <a class="facebook text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-facebook"></i></span
                  ></a>
                  <a class="twitter text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-twitter"></i></span
                  ></a>
                  <a class="youtube text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-youtube"></i></span
                  ></a>
                  <a class="googlePlus text-center" href="javascript:void(0)"
                    ><span><i class="fa fa-google"></i></span
                  ></a>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="card">
      <div class="card-header">
        <h4 class="card-title">Linked Bank Account</h4>
      </div>
      <div class="card-body">
        <div class="form">
          <ul v-if="bankData.data()" class="linked_account">
            <li>
              <div class="row">
                <div class="col-9">
                  <div class="media">
                    <span class="mr-3"><i class="fa fa-bank"></i></span>
                    <div class="media-body">
                      <h5 v-if="bankData.data()" class="mt-0 mb-1">{{bankData.data().accountName}}</h5>
                      <h5 v-if="bankData.data()" class="mt-0 mb-1">{{bankData.data().bankName}}</h5>
                      <p v-if="bankData.data()">{{bankData.data().accountNumber}}</p>
                    </div>
                    <div class="edit-option">
                      <!-- <a href="#"><i class="fa fa-eye"></i></a> -->
                      <router-link to="add-bank-acc"><i class="fa fa-pencil"></i></router-link>
                      <!-- <a href="#"><i class="fa fa-pencil"></i></a> -->
                    </div>
                  </div>
                </div>
                <div v-if="bankData.data()" class="col-3">
                  <div v-if="bankData.data().inReview" class="verify">
                    <div class="not-verify">
                      <span><i class="la la-exclamation-triangle"></i></span>
                      <a href="#">In Review</a>
                    
                    </div>
                  </div>
                  <div v-else class="verify">
                    <div class="verified">
                      <span><i class="la la-check"></i></span>
                      <a href="#">Verified</a>
                    </div>
                  </div>
                </div>
              </div>
              <small v-if="bankData.data()" style="color:red;">{{bankData.data().reviewTxt}}</small>
            </li>
          </ul>
          <div v-else class="mt-5">
            <router-link to="/verify-step-5" class="btn btn-primary px-4 mr-3"
              >Add Bank Account</router-link
            >
            <!-- <router-link to="/verify-step-1" class="btn btn-success px-4"
              >Add Debit Account</router-link
            > -->
          </div>
          

          
        </div>
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/dashboard/Layout.vue";
import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import { bankAccountCol } from "../../data/firebaseDatabase";

export default {
  name: "Accounts",
  components: {
    Layout,
  },
  data(){
    return {
      lastLogin: firebase.auth().currentUser.metadata.lastSignInTime,
      bankData: {}
    };
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
    })
  },
  methods: {
    async getBankInfo(){
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      await bankAccountCol.doc(authUser.uid)
      .get().then((bankData)=>{
        this.bankData = bankData
      })
    }
  },
  mounted(){
    this.getBankInfo();
  }
  
};
</script>
