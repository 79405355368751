//Page: SignIn
<template>
  <div
    class="authincation section-padding"
    style="
      background-image: url(https://demo.moxcreative.com/moxpay/wp-content/uploads/sites/60/2021/08/online-payment-black-spouses-with-laptop-and-credit-card-in-kitchen.jpg);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    "
  >
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-header justify-content-center">
              <div class="mini-logo text-center">
                <router-link to="/"
                  ><img src="../assets/images/logo-long.png" width="90" alt=""
                /></router-link>
              </div>
            </div>
            <div class="card-body">
              <ValidationObserver v-slot="{ passes, invalid }">
                <form
                  method="post"
                  name="myform"
                  class="signin_validate"
                  novalidate="novalidate"
                  @submit.prevent="passes(formSubmit)"
                >
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                    name="method"
                    class="form-group"
                    tag="div"
                  >
                    <h4 class="card-title text-center">Sign in</h4>
                    <label>Email</label>
                    <input
                      type="email"
                      class="form-control error"
                      placeholder="hello@example.com"
                      name="email"
                      v-model="email"
                      aria-invalid="true"
                    />
                    <label class="error" for="email">{{ errors[0] }}</label>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="passwordFieldName|verify_password"
                    v-slot="{ errors }"
                    name="password"
                    class="form-group"
                    tag="div"
                  >
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control error"
                      placeholder="Password"
                      name="password"
                      v-model="password"
                      aria-invalid="true"
                    />
                    <label class="error" for="password">{{ errors[0] }}</label>
                  </ValidationProvider>
                  <div
                    class="form-row d-flex justify-content-between mt-4 mb-2"
                  >
                    <div class="form-group mb-0">
                      <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">Remember me</span>
                      </label>
                    </div>
                    <div class="form-group mb-0">
                      <router-link to="reset">Forgot Password?</router-link>
                    </div>
                  </div>
                  <div class="text-center">
                    <label style="color: red">{{ loginError }}</label>
                    <button
                      v-if="!submitting"
                      type="submit"
                      class="btn btn-success btn-block"
                      :disabled="invalid"
                    >
                      Sign in
                    </button>
                    <b-spinner v-else label="Spinning"></b-spinner>
                  </div>
                </form>
              </ValidationObserver>
              <div class="new-account mt-3">
                <p>
                  Don't have an account?
                  <router-link class="text-primary" to="signup"
                    >Sign up</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import firebase from "firebase/compat/app";
export default {
  name: "Signin",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: "",
      password: "",
      checkbox: "",
      submitting: false,
      loginError: "",
    };
  },
  methods: {
    async formSubmit() {
      console.log("submitting...");
      this.submitting = true;
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          sessionStorage.setItem("authUser", JSON.stringify(user.user));
          this.$router.push("/");
        })
        .catch((err) => {
          this.loginError = err.message.replaceAll("Firebase:", "");
        });
      this.submitting = false;
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        sessionStorage.setItem("authUser", JSON.stringify(user));
        console.log("going next");
        this.$router.push("/");
      } else {
        console.log("no user");
        sessionStorage.removeItem("authUser");
      }
    });
  },
};
</script>
