import Vuex from "vuex";
import Vue from "vue";
import firebaseCRUD from "./modules/firebaseCRUD";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    firebaseCRUD,
  },
});
