import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About";
import AddBankAcc from "../views/AddBankAcc";
import AddDebitCard from "../views/AddDebitCard";
import Condition from "../views/Condition";
import Accounts from "../views/dashboard/Accounts";
import Mytransactions from "../views/dashboard/Mytransactions";
import TestTransactions from "../views/dashboard/TestTransactions";
// Landing
import Index from "../views/dashboard/Index";
import Account from "../views/dashboard/settings/Account";
import SettingsPreferences from "../views/dashboard/settings/Preferences";
import Security from "../views/dashboard/settings/Security";
import Settings from "../views/dashboard/settings/Settings";
import EditAccount from "../views/editaccount";
import Payouts from "../views/dashboard/payouts";
import Companies from "../views/dashboard/companies";
import Demo from "../views/Demo";
import History from "../views/History";
import Landing from "../views/Landing.vue";
import Lock from "../views/Lock";
import Otp1 from "../views/Otp1";
import Otp2 from "../views/Otp2";
import Policy from "../views/Policy";
import Reset from "../views/Reset";
import Signin from "../views/Signin";
import Devdocs from "../views/devdocs";
import Signup from "../views/Signup";
import VerifyStep1 from "../views/VerifyStep1";
import VerifyStep2 from "../views/VerifyStep2";
import VerifyStep3 from "../views/VerifyStep3";
import VerifyStep4 from "../views/VerifyStep4";
import VerifyStep5 from "../views/VerifyStep5";
import VerifyStep6 from "../views/VerifyStep6";
import VerifEmail from "../views/VerifEmail";
import authenticate from "../auth/authenticate";
import EditCompany from "../views/EditCompany";
import PayCompany from "../views/PayCompany";

Vue.use(VueRouter);

const routes = [
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
  },
  {
    path: "/index",
    name: "Index",
    beforeEnter: authenticate,
    component: Index,
  },
  {
    path: "/Mytransactions",
    name: "Transactions",
    beforeEnter: authenticate,
    component: Mytransactions,
  },
  { path: "/PayCompany/:id", component: PayCompany, props: true },
  { path: "/payment/:id", component: PayCompany, props: true },
  // {
  //   path: "/PayCompany",
  //   name: "PayCompany",
  //   component: PayCompany
  // },
  {
    path: "/TestTransactions",
    name: "TestTransactions",
    beforeEnter: authenticate,
    component: TestTransactions,
  },
  {
    path: "/edit-company",
    name: "EditCompany",
    beforeEnter: authenticate,
    component: EditCompany,
    props: true,
  },
  {
    path: "/payouts",
    name: "Payouts",
    beforeEnter: authenticate,
    component: Payouts,
  },
  {
    path: "/companies",
    name: "MyCompanies",
    beforeEnter: authenticate,
    component: Companies,
  },
  {
    path: "/accounts",
    name: "Accounts",
    beforeEnter: authenticate,
    component: Accounts,
  },
  {
    path: "/history",
    name: "History",
    beforeEnter: authenticate,
    component: History,
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
  },
  {
    path: "/add-bank-acc",
    name: "AddBankAcc",
    beforeEnter: authenticate,
    component: AddBankAcc,
  },
  {
    path: "/add-debit-card",
    name: "AddDebitCard",
    beforeEnter: authenticate,
    component: AddDebitCard,
  },
  {
    path: "/edit-account",
    name: "EditAccount",
    beforeEnter: authenticate,
    component: EditAccount,
  },
  {
    path: "/verify-step-1",
    name: "VerifyStep1",
    beforeEnter: authenticate,
    component: VerifyStep1,
  },
  {
    path: "/verify-email",
    name: "VerifEmail",
    beforeEnter: authenticate,
    component: VerifEmail,
  },
  {
    path: "/verify-step-2",
    name: "VerifyStep2",
    component: VerifyStep2,
  },
  {
    path: "/verify-step-3",
    name: "VerifyStep3",
    component: VerifyStep3,
  },
  {
    path: "/verify-step-4",
    name: "VerifyStep4",
    component: VerifyStep4,
  },
  {
    path: "/verify-step-5",
    name: "VerifyStep5",
    component: VerifyStep5,
  },
  {
    path: "/verify-step-6",
    name: "VerifyStep6",
    component: VerifyStep6,
  },
  {
    path: "/otp-1",
    name: "Otp1",
    component: Otp1,
  },
  {
    path: "/otp-2",
    name: "Otp2",
    component: Otp2,
  },
  {
    path: "/settings-preferences",
    name: "SettingsPreferences",
    component: SettingsPreferences,
  },
  {
    path: "/settings-account",
    name: "Account",
    beforeEnter: authenticate,
    component: Account,
  },
  {
    path: "/settings-security",
    name: "Security",
    beforeEnter: authenticate,
    component: Security,
  },
  {
    path: "/settings",
    name: "Settings",
    beforeEnter: authenticate,
    component: Settings,
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/privacy-policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/term-condition",
    name: "Condition",
    component: Condition,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/devdocs",
    name: "Devdocs",
    component: Devdocs,
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "*",
    component: Landing,
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    // NProgress.start();
    // NProgress.set(0.1);
  }
  next();
});

export default router;
