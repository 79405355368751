<template>
  <div>
    <div id="timeline-chart"></div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";

export default {
   props: {
        previousTrans: Array,
        thisTrans: Array,
    },
  data() {
    return {};
  },

  mounted() {
    console.log("thisTrans ", this.thisTrans)
    this.initTimelineChart();
  },

  methods: {
    initTimelineChart() {
      let options = {
        chart: {
          type: "area",
          height: 300,
          foreColor: "#8C87C2",
          fontFamily: "Rubik, sans-serif",
          stacked: true,
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
          toolbar: { show: false },
        },
        colors: ["#7B6FFF", "#7395FF"],
        stroke: {
          curve: "smooth",
          width: 3,
        },
        dataLabels: { enabled: true },
        series: [
          {
            name: "Last Month",
            data: this.generateDayWiseTimeSeries(0),
          },
          {
            name: "This Month",
            data: this.generateDayWiseTimeSeries(1),
          },
        ],
        markers: {
          size: 0,
          strokeColor: "#fff",
          // strokeColor: "#7B6FFF",
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: { size: 6 },
        },
        xaxis: {
          type: "datetime",
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        yaxis: {
          show: false,
          labels: {
            offsetX: -10,
            offsetY: 0,
          },
          tooltip: { enabled: true },
        },
        grid: {
          show: true,
          padding: {
            left: -5,
            right: 5,
          },
        },
        tooltip: {
          x: { format: "dd MMM yyyy" },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100, 100],
          },
        },
      };

      let chart = new ApexCharts(
        document.querySelector("#timeline-chart"),
        options
      );

      chart.render();
    },

    generateDayWiseTimeSeries(s) {
      var prevValues = [];
      var nowValues = [];
     
      this.previousTrans.forEach((item)=>{
         
        prevValues.push({amount: parseFloat(item.amount), date: item.created.toDate().getTime()})
      });
      this.thisTrans.forEach((item)=>{
        nowValues.push({amount: parseFloat(item.amount), date: item.created.toDate().getTime()})
      })
       console.log("in chart ", this.thisTrans);
      
      let values = [
        prevValues,
        nowValues,
      ];
      let i = 0;
      let series = [];
      //add day:
      // let today = new Date();
      // var month = today.getMonth();
      // let previousStart = new Date(today.getFullYear(), today.getMonth() - 1, 22);
      
      // var result = new Date();
  // result.setDate(result.getDate() + days);
      //
      // let x = new Date("1 Apri 2022").getTime();
      while (i < values[s].length) {
        series.push([values[s][i].date, values[s][i].amount]);
        // console.log("previousStarte ",values[s][i]);
        i++;
      }
      return series;
    },
  },
};
</script>
