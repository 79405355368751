<template>
  <layout :title="false">
    <div class="content-body">
      <div class="verification section-padding">
        <div class="container h-100">
          <div class="row justify-content-center h-100 align-items-center">
            <div class="col-xl-5 col-md-6">
              <div class="auth-form card">
                <div class="card-body">
                  <div v-if="shortEmail" class="identity-content">
                      <span class="icon"><i class="fa fa-check"></i></span>
                      <h4>Check Your Email</h4>
                      <p>
                        An Email has been sent to {{shortEmail}}
                      </p>
                    </div>
                    <div v-else class="identity-content">
                      <h4>Please wait</h4>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "../components/dashboard/Layout.vue";
import firebase from "firebase/compat/app";
export default {
    data(){
        return {
            shortEmail: ""
        };
    },
  components: { Layout },
  methods: {
    async getEmail() {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      if(!user.emailVerified){
await firebase.auth().currentUser.sendEmailVerification()
      .then(()=>{
        this.shortEmail = user.email;
      })
      }
    },
  },
  mounted(){

    this.getEmail();
  }
};
</script>
