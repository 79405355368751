<template>
  <Layout :active="4" :title="true">
    <div class="content-body">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Previous Payout</h5>
                    
                  </div>
                  <div class="widget-info">
                    <h3>${{previousPayoutTotal.toFixed(2)}} XCD</h3>
                    <p class="text-success">
                      _ <span></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Next Payment</h5>
                  </div>
                  <div v-if="previousPayoutTotal == 0" class="widget-info">
                    <h3>${{nextPayoutTotal.toFixed(2)}} XCD</h3>
                    
                     <p class="text-success">
                      100%  <span><i class="las la-arrow-up"></i></span>
                    </p>
                    <!-- <p>USD</p> -->
                  </div>
                  <div v-else class="widget-info">
                    <h3>${{nextPayoutTotal.toFixed(2)}} XCD</h3>
                    <p v-if="nextPayoutTotal < previousPayoutTotal" class="text-danger">
                      {{((nextPayoutTotal - previousPayoutTotal) / previousPayoutTotal * 100).toFixed(1) }}% <span><i class="las la-arrow-down"></i></span>
                    </p>
                     <p v-else class="text-success">
                      {{((nextPayoutTotal - previousPayoutTotal) / previousPayoutTotal * 100).toFixed(1) }}%  <span><i class="las la-arrow-up"></i></span>
                    </p>
                    <!-- <p>USD</p> -->
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="widget-card">
                  <div class="widget-title">
                    <h5>Total Payouts</h5>
                  </div>
                  <div class="widget-info">
                    <h3>${{totalPayout.toFixed(2)}} XCD</h3>
                    <!-- <p>USD</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div style="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <div class="row justify-content-space-between w-full">
                      <div class="col-md-4">
                        <h4 class="card-title">Payout</h4>
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col=md-4">
                            <b-form-select v-model="selectedRange" :options="rangeList"></b-form-select>
                          </div>
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  <div class="card-body">
                    <div class="transaction-widget">
                      <ul class="list-unstyled">
                        <li v-for="payout in payouts" :key="payout.id" class="media">
                          <span v-if="payout.status == 'deposited'" class="buy-thumb"
                            ><i class="la la-arrow-up"></i
                          ></span>
                          <span v-else class="sold-thumb"
                            ><i class="la la-exclamation-triangle"></i
                          ></span>
                          <div class="media-body">
                            <p>
                              <small>Created : {{customFormatter(payout.data().created.toDate())}}</small>
                              <!-- <small>15:33 AM</small> -->
                            </p>
                            <p class="wallet-address text-dark">
                              Trans Id: {{payout.id}}
                            </p>
                            <p class="wallet-address text-dark">
                              Total Transactions: {{payout.data().totalTransactions}}
                            </p>
                          </div>
                          <div class="media-body">
                            
                            <p class="wallet-address text-dark">
                              To Bank
                            </p>
                            <p>
                              <small>{{payout.data().bankDetails.bankName}}</small>
                            <br />
                             <small> ACCT #: {{payout.data().bankDetails.accountNumber}} Routing: {{payout.data().bankDetails.accountRouting}}</small>
                             <br />
                             <small> ACCT Name : {{payout.data().bankDetails.accountName}}</small>
                             <br />
                             <small><b>Account {{payout.data().bankDetails.inReview ? 'In Review': 'Approved'}}</b></small>
                             <br />
                             
                            </p>
                          </div>
                          <div class="text-right">
                            <h4>${{ (payout.data().fullAmount - payout.data().totalFees).toFixed(2) }} XCD</h4>
                            <p>
                              <small>Fees removed (-${{payout.data().totalFees}})</small>
                            </p>
                            <small>{{payout.data().status}}</small>
                            <br />
                            <small v-if="payout.data().depositDate">Deposited: {{customFormatter(payout.data().depositDate.toDate()) }}</small>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
        
      </div>
    </div>
    <!-- <pop v-show="showVideo" :closeFuntion="closeFuntion" /> -->
  </Layout>
</template>

<script>
import Layout from "../../components/dashboard/Layout.vue";
// import Pop from "../../components/dashboard/Pop.vue";
import moment from "moment";
import { payoutsCol } from "../../data/firebaseDatabase";


export default {
  name: "Index",
  components: {
    Layout,
    // Pop,
  },
  data() {
    return {
      payouts: [],
      showVideo: false,
        selectedRange: 7,
        previousPayoutTotal: 0,
        nextPayoutTotal: 0,
        totalPayout: 0,
        rangeList: [
          { value: 1, text: 'Today' },
          { value: 7, text: 'Last 7 days' },
          { value: 30, text: 'Last 30 days' },
        ]
    };
  },
  methods: {
    customFormatter(date) {
      return moment(date).format(" DD MMMM YYYY h:mm a");
    },
    closeFuntion() {
      this.showVideo = false;
    },
    async getAllPayouts(){
      this.totalPayout = 0;
      this.nextPayoutTotal = 0;
      this.previousPayoutTotal = 0;
      const authUser = JSON.parse(sessionStorage.getItem("authUser"));
      await payoutsCol.where("owner", "==", authUser.uid)
      .orderBy("created", "desc").get().then((payoutData)=>{
        if(payoutData.size > 0){
          this.payouts = payoutData.docs;
          payoutData.docs.forEach((pData)=>{
            let tempTotal = pData.data().fullAmount - pData.data().totalFees;
            if(pData.data().status == "queued"){
              this.nextPayoutTotal = tempTotal;
              this.totalPayout = this.totalPayout + tempTotal;
            }else{
              this.totalPayout = this.totalPayout + tempTotal;
            this.previousPayoutTotal = this.previousPayoutTotal + tempTotal;
            }
            
          })
        }
      })
    }
  },
  mounted(){
    this.getAllPayouts();
  }
};
</script>
