<template>
  <div id="main-wrapper pt-0">
    <Nav />
    <div>
      <div style="margin-top:100px;" class="row justify-content-center align-items-center">
   
          <div class="content-body" style="margin-left: 0px;">
        <div class="container">
            <p>You can accept payments from your website and your mobile applications. Select the development environment below and follow the instructions.</p>
            <div class="row">
                <div class="col-xl-12">
                        <div>
  <b-card no-body>
    <b-tabs pills card horizontal>
      <b-tab title="Flutter" active>
          <b-card-text>
          <h5>Setting up GetIt Payments in your flutter project</h5>
          <p>Please note, Getit payments currently only works with merchants located in Saint Lucia. Please ensure you have your <b>companyId</b> and <b>public key</b>. This can be found in your company page.</p>
          
          <p>First step is to download the package on <a style="color:blue;" href="https://pub.dev/packages/flutter_getitpayment">pub.dev</a>. Once installed, you can use it in this way : </p>
          <p style="background-color:#f8f8f8;" ><pre><code>
              //first initialize package
void main() async{
  GetItPayments.initialize(companyID: "YOURS_HERE",
      publicKeySTRING: "-----BEGIN RSA PUBLIC KEY-----YOUR_PUBLIC_KEY-----END RSA PUBLIC KEY-----");

  runApp(const MyApp());
}
//then use where necessary:
ServerResponse? sR = await getItPayments.startTransaction(amountToPay: 200, currency: "XCD", context: context);
                        if(sR == null){
                          print("Nothing there");
                        }else{
                          print("status : "+sR.status.toString());
                          print("message : "+sR.msg.toString());
                        }
          </code></pre></p>
          <h6 style="margin-top:20px;">
                 Server response object
             </h6>
             <code>
                 {
                     status:String,
                     message:String,
                     transactionNumber: String,
                     invoiceNumber: String,
                     error: String
                 }
             </code>
             <h6 style="margin-top:20px;">
                 Results
             </h6>
             <p>If <code>status</code> is equal to <code>Approved</code> then the payment went through.</p>
             <p>If <code>status</code> is equal to <code>Declined</code> the card has been declined</p>
             <p>If <code>status</code> is equal to <code>Incomplete</code> wrong information was sent.</p>
          </b-card-text>
          </b-tab>
      <b-tab title="Javascript">
          <b-card-text>
             <h5>Setting up GetIt Payments in javascript</h5>
             <p>Please note, Getit payments currently only works with merchants located in Saint Lucia. Please ensure you have your <b>companyId</b> and <b>public key</b>. This can be found in your company page.</p>
             <h6>
                 Parameters
             </h6>
             <div class="row"> 
                 <div class="col-lg-2">
                     <table style="width:100%;">
                         <tr>
                             <td style="border: 1px solid black;border-collapse: collapse;">
                                  <span>companyId </span>
                             </td>
                             <td style="border: 1px solid black;border-collapse: collapse;">
                                 <small>String</small>
                             </td>
                         </tr>
                          <tr>
                             <td style="border: 1px solid black;border-collapse: collapse;">
                                  <span>encryptedTxt </span>
                             </td>
                             <td style="border: 1px solid black;border-collapse: collapse;">
                                 <small>String</small>
                             </td>
                         </tr>
                     </table>
                    
                 </div>
             </div>
             <h6 style="margin-top:20px;">
                 encryptedTxt
             </h6>
             <small>Referrs to an RSA encrypted version of your json encoded payment information object.</small>
             <h6>Payment object</h6>
             <div class="row">
                 <div class="col-lg-6">
                     <code>
                 {
      'name': name,
      'amount': amount.toStringAsFixed(2),
      'currency': currency,
      'month': month,
      'year': year,
      'cvv': cvv,
      'cardNumber':cardNumber
    };
             </code>
                 </div>
             </div>
             
             <hr class="dashed">
             <p>Send payment request via <code>POST</code> to <code>https://us-central1-getitpayments.cloudfunctions.net/makPayment</code></p>
             <h6 style="margin-top:20px;">
                 Server response object
             </h6>
             <code>
                 {
                     status:String,
                     message:String,
                     transactionNumber: String,
                     invoiceNumber: String,
                     error: String
                 }
             </code>
             <h6 style="margin-top:20px;">
                 Results
             </h6>
             <p>If <code>status</code> is equal to <code>Approved</code> then the payment went through.</p>
             <p>If <code>status</code> is equal to <code>Declined</code> the card has been declined</p>
             <p>If <code>status</code> is equal to <code>Incomplete</code> wrong information was sent.</p>
              </b-card-text>
      </b-tab>
      <!-- <b-tab title="Tab 3"><b-card-text>Tab contents 3</b-card-text></b-tab> -->
    </b-tabs>
  </b-card>
</div>
                    
                </div>
            </div>
        </div>
    </div>
       
      </div>
    </div>
    <Footer />

    <CookieAlert />
  </div>
</template>

<script>
import Nav from "../components/landing/Nav";
import Footer from "../components/landing/Footer.vue";
export default {
    components: {
        Nav,
        Footer
    },
    name: "Devdocs",
}
</script>

<style>

</style>