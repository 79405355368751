<template>
  <div
    class="intro section-padding position-relative"
    id="intro"
    data-scroll-index="0"
    style="background-image: url(https://demo.moxcreative.com/moxpay/wp-content/uploads/sites/60/2021/08/online-payment-black-spouses-with-laptop-and-credit-card-in-kitchen.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-7 col-md-6">
          <div class="intro-content">
            <h2>A Payment Gateway <br />For Businesses</h2>
            <p>
              Fast and secure payment transfer for business websites and apps
            </p>
            <div class="mt-4">
              
              <a href="#" class="btn my-1 waves-effect btn-primary">
                Learn More
                
                <!-- <img src="../../assets/images/android.svg" alt="" /> -->
              </a>
              <router-link to="/signin" class="btn my-1 waves-effect btn-primary"
                    >Get Started
                    <!-- <img src="../../assets/images/apple.svg" alt="" /> -->
                    </router-link
                  >
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-md-6 py-md-5">
          <!-- <div class="card">
            <div class="card-body">
              <currency-form />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CurrencyForm from "../CurrencyForm.vue";

export default {
  name: "Intro",
  // components: { CurrencyForm },
};
</script>
