import firebase from "firebase/compat/app";
export default (to, from, next) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            sessionStorage.setItem("authUser", JSON.stringify(user));
            console.log("going next");
        next();
        } else {
            console.log("no user");
            sessionStorage.removeItem('authUser');
            next("/signin");
        }
    });
    // if(
    //     this.$cookies.get("userInfo") !=null &&
    //     this.$cookies.get("userInfo").length > 0
    // ){
    //     console.log("going next");
    //     next();
    // }else{
    //     next("/signin");
    // }


    // if (
    //   localStorage.getItem("userInfo") != null &&
    //   localStorage.getItem("userInfo").length > 0
    // ) {
    //     console.log("going next");
    //   next();
    // } else {
    //   localStorage.removeItem("userInfo");
    //   next("/signin");
    // }
  };